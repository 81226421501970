import { Box, Grid } from "@mui/material";
import CallOverviewComponent from "./CallOverviewComponent";
import CampaignsTableComponent from "./CampaignsTableComponent";
import LiveCallsTableComponent from "./LiveCallsTableComponent";
import { useState } from "react";
import ReportsTabComponent from "./ReportsTabComponent";

const AllReports = () => {
  const [selectedRow, setSelectedRow] = useState(null);
  const handleRowClick = (row) => {
    setSelectedRow(row);
  };
  const tableData = selectedRow ? selectedRow.row.tableData : [];
  const data = selectedRow ? selectedRow.row : [];

  return (
    <Box sx={{ flexGrow: 1, mt: 2 }}>
      <Grid container spacing={"22px"}>
        <Grid item xs={12} md={12} lg={8}>
          <Grid container direction="column" spacing={"22px"}>
            <Grid item>
              <CallOverviewComponent />
            </Grid>
            <Grid item>
              <ReportsTabComponent />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={4}>
          <Grid container direction="column" spacing={"22px"}>
            <Grid item>
              <CampaignsTableComponent handleRowClick={handleRowClick} />
            </Grid>
            <Grid item>
              <LiveCallsTableComponent tableData={tableData} data={data} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AllReports;
