import { Box, Card, Grid } from "@mui/material";
import CallOverviewComponent from "./CallOverviewComponent";
import ReportComponent from "./ReportsComponent";

const ReportStats = () => {
  return (
    <Box sx={{ flexGrow: 1, mt: 2 }}>
      <Grid container spacing={"22px"}>
        <Grid item xs={12} md={12} lg={8} sm={12}>
          <Grid container direction="column" spacing={"22px"}>
            <Grid item>
              <CallOverviewComponent />
            </Grid>
            <Grid item>
              <ReportComponent />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={4} sm={12}>
          <Grid container direction="column" spacing={"22px"}>
            <Grid item xs={4}>
              <Card sx={{ height: "44vh" }}></Card>
            </Grid>
            <Grid item xs={3}>
              <Card sx={{ height: "43vh" }}></Card>
            </Grid>
            <Grid item xs={5}>
              <Card sx={{ height: "45.3vh" }}></Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReportStats;
