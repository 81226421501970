import React from "react";
import { Tooltip, Typography } from "@mui/material";

const TruncatedTextWithTooltip = ({ text, width }) => (
  <Tooltip title={text} placement="top">
    <Typography
      variant="body2"
      sx={{
        maxWidth: width,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        fontSize: "16px",
        color: "#555555",
      }}
    >
      {text}
    </Typography>
  </Tooltip>
);

export default TruncatedTextWithTooltip;
