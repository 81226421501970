import { Card, CardContent, Typography, Grid, Box } from "@mui/material";
import React from "react";
import ReactApexChart from "react-apexcharts";

const ApexChart = ({ series, labels }) => {
  const options = {
    chart: {
      type: "radialBar",
      height: "100%",
      minHeight: 100,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: "60%",
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: 5,
            position: "center",
            color: "#111",
            fontSize: "14px",
            fontWeight: "bold",
            show: true,
          },
          total: {
            show: false,
          },
        },
      },
    },
    stroke: {
      outerWidth: 10,
      lineCap: "round",
    },
    responsive: [
      {
        breakpoint: 0,
        options: {
          chart: {
            height: 150,
          },
        },
      },
    ],
    labels: labels,
  };

  return (
    <Box>
      <ReactApexChart options={options} series={series} type="radialBar" />
    </Box>
  );
};

const CallOverviewComponent = () => {
  const chartData = [
    { series: [70], labels: ["Successful Calls"] },
    { series: [60], labels: ["Missed Calls"] },
    { series: [80], labels: ["Total Calls"] },
    { series: [50], labels: ["Total Uploaded Files"] },
    { series: [90], labels: ["Total Campaigns"] },
  ];

  return (
    <Card
      sx={{
        flexGrow: 1,
        // boxShadow: "4px 2px 24px rgba(35, 35, 156, 0.16)",
        borderRadius: "14px",
      }}
    >
      <CardContent>
        <Typography variant="h6">Call Overview</Typography>
        <Grid container spacing={2}>
          {chartData.map((chart, index) => (
            <Grid item xs={12} sm={6} md={4} lg={2.4} key={index}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                gap="1rem"
                alignItems={"center"}
                sx={{ height: "100%", width: "100%" }}
              >
                <ApexChart series={chart.series} labels={chart.labels} />
                <Typography
                  alignSelf={"center"}
                  variant="subtitle1"
                  fontSize={"14px"}
                >
                  {chart.labels}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CallOverviewComponent;
