import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Divider,
  Tooltip,
  IconButton,
} from "@mui/material";
import {
  AssignmentTurnedIn,
  Call,
  Comment,
  ConfirmationNumber,
  FileOpen,
  PhoneInTalk,
  RestorePage,
  SmartToy,
  Voicemail,
} from "@mui/icons-material";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import {
  agentPickTicket,
  agentPickTicketv2,
} from "app/utils/api-urls/AgentApiUrls";
import { useMutation } from "@tanstack/react-query";
import { instance } from "app/utils/axiosRequest";

const CardComponent = ({ cardData }) => {
  const history = useHistory();
  const handleActionClick = (ticketId) => {
    history.push(`/app/v2/create-ticket/${ticketId}`);
  };

  const pickTicketMutation = useMutation(
    ({ ticketId }) =>
      instance.patch(`${agentPickTicketv2.agent_pick_ticket}/${ticketId}`),
    {
      onSuccess: (data, { ticketId }) => {
        // Assuming data is the response from your mutation
        // Redirect here with the ticketId
        history.push(`/app/v2/create-ticket/${ticketId}`);
        // Optionally, invalidate or refetch queries here if your app's state needs to be updated
        // queryClient.invalidateQueries(['some-key']);
      },
      onError: (error, { ticketId }) => {
        // Handle error state here, e.g., setting snackbar state or logging
        console.error(`Failed to pick ticket ${ticketId}:`, error);
        // Assuming setSnackBar is defined somewhere to show error messages
        // setSnackBar({
        //   open: true,
        //   severity: "error",
        //   message: "Failed to pick ticket",
        // });
      },
    }
  );

  const handlePickTicket = (ticketId) => {
    pickTicketMutation.mutate({ ticketId });
  };

  return (
    <Grid container spacing={2} mt={2}>
      {cardData?.data?.map((ticket) => (
        <Grid item xs={12} sm={6} key={ticket?.ticketId}>
          <Card sx={{ borderRadius: "10px" }}>
            <CardContent>
              <Box display="flex" flexDirection="column" gap="10px">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box display="flex" alignItems="center" gap={1}>
                    <Typography
                      fontWeight="bold"
                      variant="body2"
                      color="#23239C"
                    >
                      #{ticket?.ticketId}
                    </Typography>
                    <Typography variant="body2">
                      {dayjs(ticket?.createdAt).format("YYYY/MM/DD")} |{" "}
                      {dayjs(ticket?.createdAt).format("HH:mm:ss")} |{" "}
                      {ticket.openedBy?.firstName}({ticket.openedBy?.extension})
                    </Typography>
                    {ticket?.typeId?.name === "Manual" && (
                      <ConfirmationNumber sx={{ color: "#23239C" }} />
                    )}
                    {ticket?.typeId?.name === "Chat" && (
                      <SmartToy sx={{ color: "#23239C" }} />
                    )}
                    {ticket?.typeId?.name === "Call" && (
                      <Call sx={{ color: "#23239C" }} />
                    )}
                    {ticket?.typeId?.name === "Voicemail" && (
                      <Voicemail sx={{ color: "#23239C" }} />
                    )}
                  </Box>
                  <Box display="flex" alignItems="center" gap={1}>
                    {ticket.action.includes("update") &&
                    !["closed", "raised and closed"].includes(
                      ticket.statusId?.name
                    ) ? (
                      <Tooltip title="Update Ticket">
                        <IconButton
                          onClick={() => handleActionClick(ticket._id)}
                          aria-label="update ticket"
                          sx={{
                            backgroundColor: "#EEEEEE",
                          }}
                        >
                          <FileOpen
                            sx={{ color: "#23239C", cursor: "pointer" }}
                          />
                        </IconButton>
                      </Tooltip>
                    ) : null}

                    {ticket.action.includes("pick") &&
                      !ticket.action.includes("update") && (
                        <Tooltip title="Pick Ticket">
                          <IconButton
                            onClick={() => handlePickTicket(ticket._id)}
                            aria-label="update ticket"
                            sx={{
                              backgroundColor: "#EEEEEE",
                            }}
                          >
                            <AssignmentTurnedIn
                              sx={{ color: "#23239C", cursor: "pointer" }}
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                    {/* Only show view action if update is not present */}
                    {ticket.action.includes("view") &&
                      !ticket.action.includes("update") && (
                        <Tooltip title="Add comment">
                          <IconButton
                            aria-label="update ticket"
                            sx={{
                              backgroundColor: "#EEEEEE",
                            }}
                            onClick={() => handleActionClick(ticket._id)}
                          >
                            <Comment
                              sx={{ color: "#23239C", cursor: "pointer" }}
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                    {ticket.action.includes("update") &&
                      ["raised and closed", "closed"].includes(
                        ticket.statusId?.name
                      ) && (
                        <Tooltip title="Re-open Ticket">
                          <IconButton
                            aria-label="update ticket"
                            sx={{
                              backgroundColor: "#EEEEEE",
                            }}
                            onClick={() => handleActionClick(ticket._id)}
                          >
                            <RestorePage
                              sx={{ color: "#23239C", cursor: "pointer" }}
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                  </Box>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box display="flex" alignItems="center" gap={1}>
                    <Typography variant="h6">
                      {ticket.clientInfo.clientName}(
                      {ticket.clientInfo.clientId})
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center" gap={1}>
                    <PhoneInTalk sx={{ color: "#23239C" }} />
                    <Typography color="#23239C" variant="body2">
                      {ticket.clientInfo.clientPhone}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box display="flex" alignItems="center" gap={1}>
                    <Typography variant="body2">Status:</Typography>
                    <Typography
                      fontWeight="bold"
                      variant="body2"
                      sx={{
                        textTransform: "uppercase",
                        color:
                          ticket.statusId.name === "closed"
                            ? "#008000"
                            : ticket.statusId.name === "assigned"
                            ? "#D49B54"
                            : "#008000",
                      }}
                    >
                      {ticket.statusId.name}
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center" gap={1}>
                    <Typography variant="body2">Assigned To:</Typography>
                    <Typography fontWeight="bold" variant="body2">
                      {ticket.assignedTo
                        ? `${ticket.assignedTo?.firstName}(${ticket.assignedTo?.extension}) - ${ticket.assignedDepartment?.name}`
                        : ticket.assignedDepartment?.name}
                    </Typography>
                  </Box>
                </Box>
                <Divider sx={{ marginTop: ".15rem" }} />
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box display="flex" alignItems="center" gap={1}>
                    <Typography variant="body2">Issue Category:</Typography>
                    <Typography fontWeight="bold" variant="body2">
                      {ticket.issueId?.name}
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center" gap={1}>
                    <Typography variant="body2">Sub Issue Category:</Typography>
                    <Typography fontWeight="bold" variant="body2">
                      {ticket.subIssueId.name}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box display="flex" alignItems="center" gap={1}>
                    <Typography variant="body2">
                      Latest Remarks: {ticket.remarks}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default CardComponent;
