import { Box, Grid, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import FullpageLoader from "app/components/FullpageLoader/FullpageLoader";
import BarChart from "app/components/Shared/BarChart/BarChart";
import CustomExport from "app/components/Shared/CustomExport/CustomExport";
import CustomScrollBarBox from "app/components/Shared/CustomScrollBarBox/CustomScrollBarBox";
import ProgressBar from "app/components/Shared/ProgressBar/ProgressBar";
import SelectComponent from "app/components/Shared/Select/Select.component";
import CustomPagination from "app/shared/Pagination/Pagination.component";
import { adminReportStatsTicketReport } from "app/utils/api-urls/AdminAPiUrls";
import { instance } from "app/utils/axiosRequest";
import qs from "qs";
import { useState } from "react";

const TicketReport = () => {
  const [filterData, setFilterData] = useState({
    page: 1,
    limit: 10,
  });

  const { data, isLoading, error, refetch, isRefetching } = useQuery(
    ["admin-report-stats-ticket-report", filterData],
    () =>
      instance.get(
        adminReportStatsTicketReport.admin_report_stats_ticket_report +
          "?" +
          qs.stringify(filterData)
      )
  );

  // end point for the data in ticket report table
  const rows = data?.data?.data?.ticketReportTable?.data || [];
  // endpoint for the total data in ticket report table
  const totalRows = data?.data?.data?.ticketReportTable?.totalData || 0;
  // endpoint for the total tickets of ticket volumn
  const totalTickets = data?.data?.data?.ticketVolume?.[0]?.totalTickets || 0;

  const columns = [
    { field: "departmentId", headerName: "ID", flex: 1, hide: true },
    { field: "departmentName", headerName: "Department", flex: 1 },
    { field: "assigned", headerName: "Assigned", flex: 1 },
    { field: "closed", headerName: "Closed", flex: 1 },
    { field: "raisedAndClosed", headerName: "Raised And Closed", flex: 1 },
    { field: "total", headerName: "Total Tickets", flex: 1 },
    { field: "avgResTime", headerName: "Avg. Resolution Time", flex: 1 },
  ];

  // endpoint for the ticket volume
  const progressBarsData =
    data?.data?.data?.ticketVolume
      ?.map((item) =>
        item.ticketsByStatus.map((statusItem) => {
          let barColor;
          switch (statusItem.name) {
            case "closed":
              barColor = "#4E9F3D";
              break;
            case "raised and closed":
              barColor = "#23239C";
              break;
            case "assigned":
              barColor = "#EFCB63";
              break;
            default:
              barColor = "#000000";
              break;
          }
          const capitalizedWords = statusItem.name
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");

          return {
            name: capitalizedWords,
            progressData: statusItem.totalTickerPerStatus,
            progressPercent: statusItem.percentage,
            barColor: barColor,
          };
        })
      )
      .flat() || [];

  // endpoint for the top problems
  const topIssuesData = data?.data?.data?.topIssues || [];
  const barData = {
    data: topIssuesData.map((item) => item.ticketCount || 0),
    xAxisTitle: "No. of Tickets",
    seriesName: topIssuesData.map((item) => item.issueName || ""),
  };

  //pagination left click
  const handleLeftClick = () => {
    setFilterData({
      ...filterData,
      page: --filterData.page,
    });
    refetch();
  };
  //pagination right click
  const handleRightClick = () => {
    setFilterData({
      ...filterData,
      page: ++filterData.page,
    });
    refetch();
  };

  const handleFirstPageClick = () => {
    setFilterData({
      ...filterData,
      page: 1,
    });
    refetch();
  };

  const handleLastPageClick = () => {
    setFilterData({
      ...filterData,
      page: data?.data?.totalPages,
    });
    refetch();
  };

  const handlePageChange = (page) => {
    setFilterData((prevState) => ({
      ...prevState,
      page: page,
    }));
    refetch();
  };

  // the endpoints for the api of download ticket report table
  const optionsWithEndpoints = {
    csv: adminReportStatsTicketReport.admin_report_stats_ticket_report,
  };

  return (
    <Box sx={{ height: "100%", pl: 2, pr: 2 }}>
      <FullpageLoader open={isLoading || isRefetching} />
      <Grid container justifyContent={"space-evenly"} height={"45vh"} mb={2}>
        <Grid item xs={6}>
          <Box>
            <Box sx={{ display: "flex", mb: 5 }}>
              <Typography variant="h6">Ticket Volume</Typography>
              <Typography variant="h6" ml={6}>
                {totalTickets}
              </Typography>
            </Box>
            {progressBarsData.map((data, index) => (
              <ProgressBar
                key={index}
                name={data.name}
                progressData={data.progressData}
                barColor={data.barColor}
                progressPercent={data.progressPercent}
              />
            ))}
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box>
            <Typography variant="h6">Top Issues</Typography>
            <BarChart
              data={barData.data}
              xAxisTitle={barData.xAxisTitle}
              seriesNames={barData.seriesName}
            />
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ mb: 2, height: "70vh" }}>
        <Box sx={{ mb: 1, display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6">Ticket Report Table</Typography>
          <Box sx={{ width: 120 }}>
            <CustomExport
              label="Export"
              optionsWithEndpoints={optionsWithEndpoints}
            />
          </Box>
        </Box>
        <CustomScrollBarBox height={"55vh"} scrollbarWidth={"4px"} mb={2}>
          <DataGrid
            rows={rows}
            columns={columns}
            autoHeight
            hideFooter
            getRowId={(rows) => rows["departmentId"]}
          />
        </CustomScrollBarBox>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body2">Rows</Typography>
            <Box
              sx={{
                ml: 2,
                mr: 2,
                width: "70px",
              }}
            >
              {/* component to display the rows */}
              {/* <SelectComponent
                label={"10"}
                name={"Rows"}
                required
              /> */}
            </Box>
            <Typography variant="body2">Total Records: {totalRows}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <CustomPagination
              currentPage={filterData?.page}
              totalPage={data?.data?.data?.ticketReportTable?.totalPages}
              handleLeftClick={handleLeftClick}
              handleRightClick={handleRightClick}
              handleFirstPageClick={handleFirstPageClick}
              handleLastPageClick={handleLastPageClick}
              handlePageClick={handlePageChange}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TicketReport;
