import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  InputAdornment,
  OutlinedInput,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  alpha,
} from "@mui/material";
import DatePicker from "app/components/Shared/DatePicker/DatePicker.component";
import SelectComponent from "app/components/Shared/Select/Select.component";
import TimePickerComponent from "app/components/Shared/TimePicker/TimePicker.component";
import dayjs from "dayjs";
import React, { useState } from "react";

const CreateCampaign = ({ onBack, campaign, mode }) => {
  const [selectedLevel, setSelectedLevel] = useState(1);

  const initialCampaignData = {
    campaignName: "",
    fromDate: dayjs().subtract(7, "days").format("YYYY-MM-DD"),
    toDate: dayjs().format("YYYY-MM-DD"),
    fromTime: dayjs(),
    toTime: dayjs(),
    trunk: "",
    callAttempt: "",
    timeInterval: "",
    ringTimeout: "",
  };


  const [campaignData, setCampaignData] = useState(
    mode === "create" ? initialCampaignData : campaign || initialCampaignData
  );

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCampaignData({
      ...campaignData,
      [name]: value,
    });
  };

  const handleRadioChange = (level) => {
    setSelectedLevel(level);
  };

  const audioLabels = ["First", "Second", "Third"];

  const handleFromDate = (value) => {
    setCampaignData({
      ...campaignData,
      fromDate: dayjs(value).format("YYYY-MM-DD"),
    });
  };

  const handleToDate = (value) => {
    setCampaignData({
      ...campaignData,
      toDate: dayjs(value).format("YYYY-MM-DD"),
    });
  };

  const handleFromTime = (event) => {
    const formattedTime = dayjs(event.target.value, "HH:mm").format("HH:mm");
    setCampaignData({
      ...campaignData,
      fromTime: formattedTime,
    });
  };

  const handleToTime = (event) => {
    const formattedTime = dayjs(event.target.value, "HH:mm").format("HH:mm");
    setCampaignData({
      ...campaignData,
      toTime: formattedTime,
    });
  };

  const statusOptions = [
    {
      id: 1,
      value: "holiday-greeting",
      label: "Holiday Greeting",
    },
    {
      id: 2,
      value: "new-year",
      label: "New Year",
    },
    {
      id: 3,
      value: "lorem-ipsum",
      label: "Lorem Ipsum",
    },
    {
      id: 4,
      value: "lorem-ipsum",
      label: "Lorem Ipsum",
    },
    {
      id: 5,
      value: "lorem-ipsum",
      label: "Lorem Ipsum",
    },
  ];

  const handleBack = () => {
    onBack();
  };

  const handleSaveData = () => {
    setCampaignData({
      campaignName: "",
      fromDate: dayjs().subtract(7, "days").format("YYYY-MM-DD"),
      toDate: dayjs().format("YYYY-MM-DD"),
      fromTime: dayjs(),
      toTime: dayjs(),
      trunk: "",
      callAttempt: "",
      timeInterval: "",
      ringTimeout: "",
    });
  };

  return (
    <Box sx={{ height: "85vh", overflowY: "hidden" }}>
      <Box
        marginTop={1}
        ml={2}
        sx={{
          height: "75vh",
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: "6px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#ccc",
            borderRadius: "3px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "#aaa",
          },
        }}
      >
        <Box sx={{ display: "flex", justifyItems: "center" }}>
          <ArrowBackIosNewOutlinedIcon
            sx={{ mr: 2, mt: 0.5, cursor: "pointer", color: "#23239C" }}
            onClick={handleBack}
          />
          <Typography variant="h6" sx={{ mb: 1 }}>
            {mode === "create"
              ? "Create Campaign"
              : mode === "edit"
              ? "Edit Campaign"
              : "View Campaign"}
          </Typography>
        </Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mr: 1,
              mb: 2,
              borderRadius: 2,
              // backgroundColor: alpha("#23239C", 0.05),
              backgroundColor:
                mode === "create"
                  ? alpha("#23239C", 0.05)
                  : alpha("#AAAAAA", 0.05),
              border: "1px solid rgba(35, 35, 156, 0.1)",
            }}
          >
            <Typography variant="body2" sx={{ alignItems: "center", m: 1 }}>
              Campaign Name
            </Typography>
            <TextField
              hiddenLabel
              value={campaignData?.campaignName}
              onChange={handleChange}
              name="campaignName"
              disabled={mode === "view"}
              sx={{
                m: 1,
                mr: 2,
                display: "flex",
                backgroundColor: "white",
                border: "1px solid rgba(35, 35, 156, 0.4)",
                borderRadius: 2,
                "& .MuiInputBase-root": {
                  height: "15px",
                  width: 310,
                  paddingTop: 2,
                  paddingBottom: 2,
                  borderRadius: 2,
                },
              }}
            />
          </Box>
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mr: 1,
                mb: 2,
                borderRadius: 2,
                backgroundColor:
                  mode === "create"
                    ? alpha("#23239C", 0.05)
                    : alpha("#AAAAAA", 0.05),
                border: "1px solid rgba(35, 35, 156, 0.1)",
              }}
            >
              <Typography
                variant="body2"
                sx={{ m: 1, mb: 1, alignItems: "center" }}
              >
                Level
              </Typography>
              <Box sx={{ display: "flex", mr: 1 }}>
                <RadioGroup
                  value={selectedLevel}
                  onChange={(event) =>
                    handleRadioChange(parseInt(event.target.value))
                  }
                  sx={{ display: "flex", flexDirection: "row" }}
                >
                  {[1, 2, 3].map((level, index) => (
                    <FormControlLabel
                      key={index}
                      control={
                        <Radio
                          sx={{
                            "&.Mui-checked": {
                              color: alpha("#23239C", 0.9),
                            },
                          }}
                        />
                      }
                      label={level.toString()}
                      value={level.toString()}
                    />
                  ))}
                </RadioGroup>
              </Box>
            </Box>
            {selectedLevel !== null && (
              <Box sx={{ mb: 2, mt: 1 }}>
                <Typography variant="body2">
                  {audioLabels.slice(0, selectedLevel).map((label, index) => (
                    <Box
                      key={index}
                      sx={{
                        mb: 2,
                        mt: 1,
                        mr: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        borderRadius: 2,
                        backgroundColor:
                          mode === "create"
                            ? alpha("#23239C", 0.05)
                            : alpha("#AAAAAA", 0.05),
                        border: "1px solid rgba(35, 35, 156, 0.1)",
                      }}
                    >
                      <Typography variant="body2" sx={{ m: 1 }}>
                        {label + " Audio File"}
                      </Typography>
                      <Box
                        display={"flex"}
                        width={200}
                        sx={{ backgroundColor: "white", m: 1, mr: 2 }}
                      >
                        <SelectComponent
                          options={statusOptions}
                          label={"Select Audio"}
                        />
                      </Box>
                    </Box>
                  ))}
                </Typography>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mr: 1,
              mb: 2,
              borderRadius: 2,
              backgroundColor:
                mode === "create"
                  ? alpha("#23239C", 0.05)
                  : alpha("#AAAAAA", 0.05),
              border: "1px solid rgba(35, 35, 156, 0.1)",
            }}
          >
            <Typography
              variant="body2"
              sx={{ mb: 1, alignItems: "center", mt: 1, m: 1 }}
            >
              Schedule Date
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: 320,
                m: 1,
              }}
            >
              <Box
                mr={1}
                sx={{ backgroundColor: "white" }}
                disabled={mode === "view"}
              >
                <DatePicker
                  label={"From"}
                  value={campaignData?.fromDate}
                  handleChange={handleFromDate}
                  name="fromDate"
                  disabled={mode === "view"}
                />
              </Box>
              <Box
                mr={1}
                sx={{ backgroundColor: "white" }}
                disabled={mode === "view"}
              >
                <DatePicker
                  label={"To"}
                  value={campaignData?.toDate}
                  handleChange={handleToDate}
                  name="toDate"
                  disabled={mode === "view"}
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mr: 1,
              mb: 2,
              borderRadius: 2,
              backgroundColor:
                mode === "create"
                  ? alpha("#23239C", 0.05)
                  : alpha("#AAAAAA", 0.05),
              border: "1px solid rgba(35, 35, 156, 0.1)",
            }}
          >
            <Typography
              variant="body2"
              sx={{ m: 1, mb: 1, mt: 1, alignItems: "center" }}
            >
              Schedule Time
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: 320,
                m: 1,
              }}
            >
              <React.Fragment>
                <Box
                  mr={1}
                  sx={{
                    backgroundColor: "white",
                    width: 152,
                    borderRadius: 5,
                  }}
                >
                  <TimePickerComponent
                    label={"From"}
                    name="fromTime"
                    value={campaignData?.fromTime}
                    handleChange={handleFromTime}
                    disabled={mode === "view"}
                  />
                </Box>
                <Box
                  mr={1}
                  sx={{
                    backgroundColor: "white",
                    width: 152,
                    borderRadius: 5,
                  }}
                >
                  <TimePickerComponent
                    label={"To"}
                    name="toTime"
                    value={campaignData?.toTime}
                    handleChange={handleToTime}
                    disabled={mode === "view"}
                  />
                </Box>
              </React.Fragment>
            </Box>
          </Box>
          <Box
            sx={{
              mb: 2,
              mr: 1,
              borderRadius: 2,
              backgroundColor:
                mode === "create"
                  ? alpha("#23239C", 0.05)
                  : alpha("#AAAAAA", 0.05),
            }}
            disabled={mode === "view"}
          >
            <SelectComponent
              options={statusOptions}
              label={"Select Trunk"}
              name="trunk"
              handleChange={handleChange}
              value={campaignData?.trunk}
              required={true}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
              mb: 2,
              mr: 1,
              borderRadius: 2,
              backgroundColor:
                mode === "create"
                  ? alpha("#23239C", 0.05)
                  : alpha("#AAAAAA", 0.05),
              border: "1px solid rgba(35, 35, 156, 0.1)",
            }}
          >
            <Typography
              variant="body2"
              sx={{ m: 1, mb: 1, alignItems: "center" }}
            >
              No. of Call Attempt
            </Typography>
            <Box sx={{ m: 1, width: 110 }}>
              <TextField
                hiddenLabel
                disabled={mode === "view"}
                name="callAttempt"
                value={campaignData?.callAttempt}
                onChange={handleChange}
                sx={{
                  mr: 1,
                  display: "flex",
                  border: "1px solid rgba(35, 35, 156, 0.4)",
                  borderRadius: 2,
                  backgroundColor: "white",
                  "& .MuiInputBase-root": {
                    height: "15px",
                    paddingTop: 2,
                    paddingBottom: 2,
                    borderRadius: 2,
                  },
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
              mb: 2,
              mr: 1,
              borderRadius: 2,
              backgroundColor:
                mode === "create"
                  ? alpha("#23239C", 0.05)
                  : alpha("#AAAAAA", 0.05),
              border: "1px solid rgba(35, 35, 156, 0.1)",
            }}
          >
            <Typography
              variant="body2"
              sx={{ m: 1, mb: 1, alignItems: "center" }}
            >
              Time Interval
            </Typography>
            <FormControl
              sx={{
                m: 1,
                mr: 2,
                width: 100,
                backgroundColor: "white",
                border: "1px solid rgba(35, 35, 156, 0.4)",
                borderRadius: 2,
                "& .MuiInputBase-root": {
                  height: "15px",
                  paddingTop: 2,
                  paddingBottom: 2,
                  borderRadius: 2,
                },
              }}
              variant="outlined"
            >
              <OutlinedInput
                id="outlined-adornment-min"
                endAdornment={
                  <InputAdornment position="end">min</InputAdornment>
                }
                value={campaignData?.timeInterval}
                onChange={handleChange}
                name="timeInterval"
                disabled={mode === "view"}
              />
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
              mb: 2,
              mr: 1,
              borderRadius: 2,
              backgroundColor:
                mode === "create"
                  ? alpha("#23239C", 0.05)
                  : alpha("#AAAAAA", 0.05),
              border: "1px solid rgba(35, 35, 156, 0.1)",
            }}
          >
            <Typography
              variant="body2"
              sx={{ m: 1, mb: 1, alignItems: "center" }}
            >
              Ring Timeout
            </Typography>
            <FormControl
              sx={{
                m: 1,
                mr: 2,
                width: 100,
                backgroundColor: "white",
                border: "1px solid rgba(35, 35, 156, 0.4)",
                borderRadius: 2,
                "& .MuiInputBase-root": {
                  height: "15px",
                  paddingTop: 2,
                  paddingBottom: 2,
                  borderRadius: 2,
                },
              }}
              variant="outlined"
            >
              <OutlinedInput
                id="outlined-adornment-sec"
                endAdornment={
                  <InputAdornment position="end">sec</InputAdornment>
                }
                value={campaignData?.ringTimeout}
                onChange={handleChange}
                name="ringTimeout"
                disabled={mode === "view"}
              />
            </FormControl>
          </Box>
        </Box>
      </Box>
      {mode !== "view" && (
        <Box
          sx={{
            display: "flex",
            m: 1,
            mr: 2,
            mt: 2,
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="contained"
            onClick={handleSaveData}
            sx={{
              backgroundColor: "#23239C",
              "&:hover": {
                backgroundColor: alpha("#23239C", 0.85),
              },
            }}
          >
            Save
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default CreateCampaign;
