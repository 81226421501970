import React from "react";
import { Backdrop } from "@mui/material";
import { DotLoader, FadeLoader } from "react-spinners";

const FullpageLoader = ({ open }) => {
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <FadeLoader color="#08244a" />
      </Backdrop>
    </>
  );
};

export default FullpageLoader;
