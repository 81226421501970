import React, { useState } from "react";
import {
  Card,
  CardContent,
  TextField,
  Typography,
  Box,
  Grid,
  FormControlLabel,
  Switch,
} from "@mui/material";

const CallerDetails = ({
  callerInfo,
  handleInputChange,
  handleSwitchChange,
}) => {
  return (
    <Grid mt="1em" item xs={12} md={12}>
      <Card sx={{ borderRadius: "12px", height: "auto", padding: "1em" }}>
        {" "}
        {/* Adjusted height and added padding for aesthetic reasons */}
        <CardContent>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Typography variant="span" fontWeight="medium" fontSize="20px">
              Caller Details
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={callerInfo.self}
                  onChange={handleSwitchChange}
                  sx={{
                    "& .MuiSwitch-switchBase.Mui-checked": {
                      color: "#23239C",
                    },
                    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                      backgroundColor: "#4caf50",
                    },
                  }}
                />
              }
              label="Self"
              labelPlacement="start"
            />
          </Box>

          <Box display="flex" flexDirection="row" alignItems="center" gap="3em">
            <Typography variant="span" fontWeight="500" fontSize="20px">
              {callerInfo?.callerPhone}
            </Typography>
            {!callerInfo?.self && (
              <>
                <TextField
                  size="medium"
                  label="Name"
                  name="callerName"
                  value={callerInfo.callerName}
                  onChange={handleInputChange}
                  sx={{
                    width: 250,
                  }}
                  InputProps={{
                    sx: {
                      borderRadius: "15px",
                      border: "none",
                      backgroundColor: "#F5F5F5",
                    },
                    disableUnderline: true,
                  }}
                />
                <TextField
                  size="medium"
                  label="Relation to client"
                  name="relation"
                  value={callerInfo.relation}
                  onChange={handleInputChange}
                  sx={{
                    width: "320px",
                  }}
                  InputProps={{
                    sx: {
                      borderRadius: "15px",
                      border: "none",
                      backgroundColor: "#F5F5F5",
                    },
                    disableUnderline: true,
                  }}
                />
              </>
            )}
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default CallerDetails;
