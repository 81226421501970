import { TextField } from "@mui/material";
import React from "react";

const UpdatedRemarks = ({ duringCallValue, handleUpdateRemarksChange }) => {
  return (
    <TextField
      size="medium"
      value={duringCallValue?.updateRemarks}
      label="Remarks"
      multiline
      maxRows={1}
      name="remarks"
      onChange={handleUpdateRemarksChange}
      fullWidth
      InputProps={{
        sx: {
          borderRadius: "12px",
          backgroundColor: "#F5F5F5",
        },
        disableUnderline: true,
      }}
    />
  );
};

export default UpdatedRemarks;
