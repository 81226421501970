import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {
  Box,
  Button,
  Card,
  IconButton,
  Modal,
  Typography,
  alpha,
} from "@mui/material";
import SelectComponent from "app/components/Shared/Select/Select.component";
import { useRef, useState } from "react";

const FileManagement = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const [showStartBlasting, setShowStartBlasting] = useState(false);
  const [data, setData] = useState({
    selectedCampaign: "",
    contactFile: null,
  });


  const onSubmit = (e) => {
    e.preventDefault();
    setData(data);
    setShowStartBlasting(true);
  };

  const handleBlast = (e) => {
    e.preventDefault();

    setData({
      selectedCampaign: "",
      contactFile: null,
    });
    setSelectedFile(null);
    setShowStartBlasting(false);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setData({
      ...data,
      contactFile: file,
    });
  };

  const handleSelectChange = (event) => {
    setData({
      ...data,
      selectedCampaign: event.target.value,
    });
  };

  const handleClose = () => {
    setShowStartBlasting(false);
  };

  const statusOptions = [
    {
      id: 1,
      value: "holiday-greeting",
      label: "Holiday Greeting",
    },
    {
      id: 2,
      value: "new-year",
      label: "New Year",
    },
    {
      id: 3,
      value: "lorem-ipsum",
      label: "Lorem Ipsum",
    },
    {
      id: 4,
      value: "lorem-ipsum",
      label: "Lorem Ipsum",
    },
    {
      id: 5,
      value: "lorem-ipsum",
      label: "Lorem Ipsum",
    },
  ];

  const StartBlasting = ({ data }) => {
    return (
      <Modal onClose={handleClose} open={showStartBlasting}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          sx={{
            width: "50rem",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            boxShadow: "1 4px 6px rgba(35, 35, 156, 0.1)",
            bgcolor: "background.paper",
            p: 4,
            borderRadius: 2,
          }}
        >
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            mb={2}
          >
            <Typography variant="h6" align="left" gutterBottom ml={1}>
              Let's Review
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseOutlinedIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
              borderRadius: 2,
              backgroundColor: alpha("#AAAAAA", 0.1),
              border: "1px solid rgba(35, 35, 156, 0.1)",
            }}
          >
            <Typography variant="body1" sx={{ alignItems: "center", m: 1 }}>
              Campaign Name
            </Typography>
            <Typography variant="body1" sx={{ alignItems: "flex-end", m: 1 }}>
              {data?.selectedCampaign}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
              borderRadius: 2,
              backgroundColor: alpha("#AAAAAA", 0.1),
              border: "1px solid rgba(35, 35, 156, 0.1)",
            }}
          >
            <Typography variant="body1" sx={{ alignItems: "center", m: 1 }}>
              Audio File
            </Typography>
            <Typography variant="body1" sx={{ alignItems: "flex-end", m: 1 }}>
              {/* value={selectedFile ? selectedFile.name : ""} */}
              Here
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
              borderRadius: 2,
              backgroundColor: alpha("#AAAAAA", 0.1),
              border: "1px solid rgba(35, 35, 156, 0.1)",
            }}
          >
            <Typography variant="body1" sx={{ alignItems: "center", m: 1 }}>
              Contact Numbers
            </Typography>
            <Typography variant="body1" sx={{ alignItems: "flex-end", m: 1 }}>
              {selectedFile ? selectedFile.name : ""}
            </Typography>
          </Box>
          <Button
            variant="contained"
            onClick={handleBlast}
            sx={{
              display: "flex",
              width: 80,
              justifyItems: "end",
              alignSelf: "flex-end",
              backgroundColor: "#23239C",
              "&:hover": {
                backgroundColor: alpha("#23239C", 0.85),
              },
            }}
          >
            Blast
          </Button>
        </Box>
      </Modal>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        marginBottom: 2,
        mt: 1,
      }}
    >
      <Typography variant="h6" sx={{ mb: 2, ml: 2 }}>
        Call Blasting
      </Typography>
      <Box sx={{ ml: 2, mr: 2, backgroundColor: alpha("#23239C", 0.05) }}>
        <SelectComponent
          options={statusOptions}
          label={"Select Campaign"}
          handleChange={handleSelectChange}
          value={data.selectedCampaign}
          name={"selectedCampaign"}
          required
        />
      </Box>
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        variant="outlined"
        sx={{
          m: 2,
          border: "1px solid rgba(35, 35, 156, 0.1)",
          backgroundColor: alpha("#23239C", 0.05),
          borderRadius: 1,
          p: 1,
          alignItems: "center",
        }}
      >
        <Typography variant="body1" fontWeight={400} sx={{ pl: 1 }}>
          Contact Numbers
        </Typography>
        {selectedFile && (
          <Typography color="text.secondary" variant="body3">
            {selectedFile.name}
          </Typography>
        )}
        <input
          type="file"
          id="fileInput"
          style={{ display: "none" }}
          onChange={handleFileChange}
          ref={fileInputRef}
          accept=".csv,.xls,.xlsx"
        />
        <Button
          size="small"
          variant="outlined"
          onClick={handleButtonClick}
          component="label"
          sx={{
            display: "flex",
            justifyContent: "right",
            alignItems: "right",
            color: "#23239C",
            boxShadow: "0 0 0 0.2px rgba(35, 35, 156, 0.7)",
            "&:hover": {
              boxShadow: "0 0 0 0.2px rgba(35, 35, 156, 0.9)",
            },
          }}
        >
          <AccountBoxOutlinedIcon sx={{ mr: 0.5 }} />
          Upload
        </Button>
      </Box>
      <Box
        display={"flex"}
        sx={{
          minWidth: 275,
          minHeight: "50vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card
          display={"flex"}
          variant="outlined"
          sx={{
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(35, 35, 156, 0.05)",
            height: "20vh",
            minWidth: 300,
            mt: 2,
            mb: 2,
          }}
        >
          <Typography
            variant="body1"
            fontWeight={400}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignSelf: "center",
            }}
          >
            Preview
          </Typography>
        </Card>
      </Box>
      <Button
        variant="contained"
        onClick={onSubmit}
        sx={{
          display: "flex",
          width: 150,
          justifyItems: "end",
          alignSelf: "end",
          mr: 2,
          backgroundColor: "#23239C",
          "&:hover": {
            backgroundColor: alpha("#23239C", 0.85),
          },
        }}
      >
        Start Blasting
      </Button>
      {showStartBlasting && <StartBlasting data={data} />}
    </Box>
  );
};

export default FileManagement;
