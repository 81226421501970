import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import StopCircleOutlinedIcon from "@mui/icons-material/StopCircleOutlined";
import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined";
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";

const columns = [
  {
    field: "campaignName",
    headerName: "Campaign Name",
    flex: 1.5,
    renderCell: (params) => (
      <Box sx={{ cursor: "pointer" }}>{params.value}</Box>
    ),
  },
  { field: "toDate", headerName: "Created Date", flex: 1 },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    renderCell: (params) => (
      <Typography
        variant="body2"
        sx={{
          color:
            params.value === "Running"
              ? "#4E9F3D"
              : params.value === "Stop"
              ? "#D60000"
              : "#EFCB63",
        }}
      >
        {params.row.status}
      </Typography>
    ),
  },
  {
    field: "action",
    headerName: "Action",
    flex: 1,
    renderCell: (params) => {
      const [status, setStatus] = useState(params.row.status);

      const handlePlayPause = () => {
        if (status === "Stop") {
          setStatus("Running");
          params.row.status = "Running";
        } else if (status === "Running") {
          setStatus("Pause");
          params.row.status = "Pause";
        } else if (status === "Pause") {
          setStatus("Running");
          params.row.status = "Running";
        }
      };

      const handleStop = () => {
        setStatus("Stop");
        params.row.status = "Stop";
      };

      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {status === "Running" && (
            <Tooltip title="Pause">
              <IconButton onClick={handlePlayPause}>
                <PauseCircleOutlineIcon
                  sx={{ color: "#001A72", fontSize: 20 }}
                />
              </IconButton>
            </Tooltip>
          )}
          {(status === "Pause" || status === "Stop") && (
            <Tooltip title="Running">
              <IconButton onClick={handlePlayPause}>
                <PlayCircleFilledWhiteOutlinedIcon
                  sx={{ color: "#001A72", fontSize: 20 }}
                />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Stop">
            <IconButton onClick={handleStop}>
              <StopCircleOutlinedIcon sx={{ color: "#D60000", fontSize: 20 }} />
            </IconButton>
          </Tooltip>
        </Box>
      );
    },
  },
];

const rows = [
  {
    id: 1,
    campaignName: "Campaign 1",
    toDate: "2024-03-05",
    status: "Running",
    inProgress: 20,
    completed: 10,
    estimatedTime: 2,
    total: 50,
    tableData: [
      { id: 1, contact: "1234567890", status: "In Progress", duration: "Edit" },
      { id: 2, contact: "2345678901", status: "Ringing", duration: "Delete" },
      { id: 3, contact: "3456789012", status: "In Progress", duration: "Edit" },
      { id: 4, contact: "4567890123", status: "Ringing", duration: "Delete" },
      { id: 5, contact: "5678901234", status: "In Progress", duration: "Edit" },
      { id: 6, contact: "6789012345", status: "Ringing", duration: "Delete" },
      { id: 7, contact: "6789012345", status: "Ringing", duration: "Delete" },
      { id: 8, contact: "6789012345", status: "Ringing", duration: "Delete" },
      { id: 9, contact: "6789012345", status: "Ringing", duration: "Delete" },
      { id: 10, contact: "6789012345", status: "Ringing", duration: "Delete" },
      { id: 11, contact: "6789012345", status: "Ringing", duration: "Delete" },
      { id: 12, contact: "6789012345", status: "Ringing", duration: "Delete" },
    ],
  },
  {
    id: 2,
    campaignName: "Campaign 2",
    status: "Stop",
    toDate: "2024-03-05",
    inProgress: 10,
    completed: 100,
    estimatedTime: 2,
    total: 5,
    tableData: [
      { id: 1, contact: "1234567890", status: "In Progress", duration: "Edit" },
    ],
  },
  {
    id: 3,
    campaignName: "Campaign 3",
    status: "Running",
    toDate: "2024-03-05",
    inProgress: 10,
    completed: 100,
    estimatedTime: 2,
    total: 5,
    tableData: [
      { id: 1, contact: "1234567890", status: "In Progress", duration: "Edit" },
    ],
  },
  {
    id: 4,
    campaignName: "Campaign 4",
    status: "Running",
    toDate: "2024-03-05",
    inProgress: 10,
    completed: 100,
    estimatedTime: 2,
    total: 5,
    tableData: [
      { id: 1, contact: "1234567890", status: "In Progress", duration: "Edit" },
    ],
  },
  {
    id: 5,
    campaignName: "Campaign 5",
    status: "Pause",
    toDate: "2024-03-05",
    inProgress: 10,
    completed: 100,
    estimatedTime: 2,
    total: 5,
    tableData: [
      { id: 1, contact: "1234567890", status: "In Progress", duration: "Edit" },
    ],
  },
  {
    id: 6,
    campaignName: "Campaign 6",
    status: "Running",
    toDate: "2024-03-05",
    inProgress: 10,
    completed: 100,
    estimatedTime: 5,
    total: 5,
    tableData: [
      { id: 1, contact: "1234567890", status: "In Progress", duration: "Edit" },
    ],
  },
];

const CampaignsTableComponent = ({ handleRowClick }) => {
  return (
    <Card
      sx={{
        height: "60vh",
        // boxShadow: "4px 2px 24px rgba(35, 35, 156, 0.2)",
        borderRadius: "14px",
      }}
    >
      <CardContent>
        <Typography variant="h6" sx={{ ml: 2, py: 1 }}>
          Campaigns
        </Typography>
        <Box
          sx={{
            height: "45vh",
            overflowY: "auto",
            overflowX: "hidden",
            marginBottom: 2,
            ml: 2,
            mr: 2,
            border: "1px solid #d3d3d3",
            borderRadius: 1,
            "&::-webkit-scrollbar": {
              width: "3px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#ccc",
              borderRadius: "3px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "#aaa",
            },
          }}
        >
          <DataGrid
            rows={rows}
            columns={columns}
            autoHeight
            hideFooter
            onRowClick={handleRowClick}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default CampaignsTableComponent;
