import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { instance } from "app/utils/axiosRequest";
import IosShareIcon from "@mui/icons-material/IosShare";
import qs from "qs";
import { useState } from "react";

const CustomExport = ({ label, optionsWithEndpoints }) => {
  const [selectedOption, setSelectedOption] = useState("");

  const { mutate: downloadFile } = useMutation(
    (selectedOption) =>
      instance.get(
        optionsWithEndpoints[selectedOption] +
          "?" +
          qs.stringify(selectedOption),
        { responseType: "blob" }
      ),
    {
      onSuccess: (response) => {
        const contentType = response.data["type"];
        const fileExtension = contentType.split("/")[1];
        const fileName = `Tickets.${fileExtension}`;
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: contentType })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        setSelectedOption("");
      },
      onError: (error) => {
        console.error("Download error:", error);
        console.error("Error details:", error.response);

        setSelectedOption("");
      },
    }
  );

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    if (selectedValue) {
      downloadFile(selectedValue);
    }
  };

  return (
    <Box sx={{ minWidth: 100 }}>
      <FormControl fullWidth>
        <InputLabel
          size="small"
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <IosShareIcon sx={{ width: 20, height: 20, mr: 1 }} />
          {label}
        </InputLabel>
        <Select
          value={selectedOption}
          onChange={handleSelectChange}
          size="small"
          label={label}
          sx={{ borderRadius: 5 }}
        >
          {Object.keys(optionsWithEndpoints).map((key) => (
            <MenuItem key={key} value={key}>
              {key}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default CustomExport;
