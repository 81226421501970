import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { TextField } from "@mui/material";

const DatePicker = ({ label, value, handleChange, name, disabled }) => {
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          label={label}
          inputFormat="MM/DD/YYYY"
          disabled={disabled}
          value={value}
          name={name}
          onChange={handleChange}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              fullWidth
              sx={{
                "& .css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled":
                  {
                    WebkitTextFillColor: "rgba(19, 19, 19, 0.8) !important",
                  },
                "& .MuiInputLabel-root.Mui-disabled": {
                  color: "rgba(19, 19, 19, 0.7)",
                },
              }}
            />
          )}
        />
      </LocalizationProvider>
    </>
  );
};

export default DatePicker;
