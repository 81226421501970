import { Box, Grid, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import FullpageLoader from "app/components/FullpageLoader/FullpageLoader";
import BarChart from "app/components/Shared/BarChart/BarChart";
import CustomExport from "app/components/Shared/CustomExport/CustomExport";
import CustomScrollBarBox from "app/components/Shared/CustomScrollBarBox/CustomScrollBarBox";
import CustomDonutChart from "app/components/Shared/DonutChart/CustomDonutChart";
import CustomPagination from "app/shared/Pagination/Pagination.component";
import { adminReportStatsClientExport, adminReportStatsClientReport } from "app/utils/api-urls/AdminAPiUrls";
import { instance } from "app/utils/axiosRequest";
import qs from "qs";
import { useState } from "react";

const ClientReport = () => {
  const [filterData, setFilterData] = useState({
    page: 1,
    limit: 10,
  });

  const { data, isLoading, error, refetch, isRefetching } = useQuery(
    ["admin-report-stats-client-report", filterData],
    () =>
      instance.get(
        adminReportStatsClientReport.admin_report_stats_client_report +
          "?" +
          qs.stringify(filterData)
      )
  );

  // endpoint for the data in client report table
  const rows = data?.data?.data?.clientReport?.data || [];
  // endpoint for the total data in the table
  const totalRows = data?.data?.data?.clientReport?.totalData || 0;

  const columns = [
    { field: "clientName", headerName: "Client Name", width: 150 },
    { field: "clientId", headerName: "Client ID", width: 130 },
    { field: "clientPhone", headerName: "Client Phone", width: 120 },
    { field: "totalTickets", headerName: "Total Tickets", width: 120 },
    { field: "assigned", headerName: "Assigned", width: 100 },
    { field: "closed", headerName: "Closed", width: 100 },
    { field: "raisedAndClosed", headerName: "Raised and Closed", width: 140 },
    { field: "totalInteraction", headerName: "Total Interaction", width: 140 },
    { field: "chat", headerName: "Chatbot", width: 100 },
    { field: "call", headerName: "Direct Call", width: 100 },
    { field: "voiceMail", headerName: "Voice Mail", width: 100 },
  ];

  // endpoint for the top interacted clients
  const topInteractedClients = data?.data?.data?.interactedClients || [];
  const barData = {
    seriesName: topInteractedClients.map((item) => item.clientName || ""),
    data: topInteractedClients.map((item) => item.totalInteraction || 0),
    xAxisTitle: "No. of Interaction",
  };

  // endpoint for the preferable channel
  const preferrableChannel = data?.data?.data?.preferrableChannel || [];
  const preferrableChannelTotal = preferrableChannel?.[0]?.totalData || 0;
  const generateChannelColor = (typeName) => {
    switch (typeName) {
      case "Call":
        return "#23238C99";
      case "Voicemail":
        return "#EFCB63B3";
      case "Chat":
        return "#4E9F3D99";
      case "Manual":
        return "#D600004D";
      default:
        return "";
    }
  };
  const donutData = {
    seriesName: preferrableChannel?.[0]?.totalPerChannel?.map(
      (item) => item.typeName || ""
    ),
    seriesData: preferrableChannel?.[0]?.totalPerChannel?.map(
      (item) => item.total || 0
    ),
    seriesColors: preferrableChannel?.[0]?.totalPerChannel?.map((item) =>
      generateChannelColor(item.typeName)
    ),
  };

  //pagination left click
  const handleLeftClick = () => {
    setFilterData({
      ...filterData,
      page: --filterData.page,
    });
    refetch();
  };
  //pagination right click
  const handleRightClick = () => {
    setFilterData({
      ...filterData,
      page: ++filterData.page,
    });
    refetch();
  };

  const handleFirstPageClick = () => {
    setFilterData({
      ...filterData,
      page: 1,
    });
    refetch();
  };

  const handleLastPageClick = () => {
    setFilterData({
      ...filterData,
      page: data?.data?.data?.clientReport?.currentPage,
    });
    refetch();
  };

  const handlePageChange = (page) => {
    setFilterData((prevState) => ({
      ...prevState,
      page: page,
    }));
    refetch();
  };

  // the endpoints for the api of download ticket report table
  const optionsWithEndpoints = {
    csv: adminReportStatsClientExport.admin_report_stats_client_export_csv,
  };

  return (
    <Box sx={{ height: "100%", pl: 2, pr: 2 }}>
      <FullpageLoader open={isLoading || isRefetching} />
      <Grid container justifyContent={"space-evenly"} height={"45vh"} mb={2}>
        <Grid item xs={6}>
          <Box>
            <Box sx={{ display: "flex", mb: 2 }}>
              <Typography variant="h6">Preferable Channel</Typography>
              <Typography variant="h6" ml={6}>
                {preferrableChannelTotal}
              </Typography>
            </Box>
            <Box sx={{ height: "20vh" }}>
              <CustomDonutChart
                seriesData={donutData.seriesData}
                seriesNames={donutData.seriesName}
                seriesColors={donutData.seriesColors}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box>
            <Typography variant="h6">
              Top Interacted Clients
            </Typography>
            <BarChart
              data={barData.data}
              seriesNames={barData.seriesName}
              xAxisTitle={barData.xAxisTitle}
            />
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ mb: 2, height: "70vh" }}>
        <Box sx={{ mb: 1, display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6">Client Report Table</Typography>
          <Box sx={{ width: 120 }}>
            <CustomExport
              label="Export"
              optionsWithEndpoints={optionsWithEndpoints}
            />
          </Box>
        </Box>
        <CustomScrollBarBox height={"55vh"} scrollbarWidth={"4px"} mb={2}>
          <DataGrid
            rows={rows}
            columns={columns}
            getRowId={(row) => row.clientId}
            autoHeight
            hideFooter
            disableColumnMenu
            scrollbarSize={4}
            scrollbarWidth={4}
          />
        </CustomScrollBarBox>
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="body2">Rows</Typography>
              <Box
                sx={{
                  ml: 2,
                  mr: 2,
                  display: "flex",
                }}
              >
                 {/* component to display the rows */}
                {/* <SelectComponent
                  // options={statusOptions}
                  label={"Select Campaign"}
                  // handleChange={handleSelectChange}
                  // value={data.selectedCampaign}
                  name={"selectedCampaign"}
                  required
                /> */}
              </Box>
            </Box>
            <Typography variant="body2">Total Records: {totalRows}</Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
            }}
          >
            <CustomPagination
              currentPage={filterData?.page}
              totalPage={data?.data?.data?.clientReport?.totalPages}
              handleLeftClick={handleLeftClick}
              handleRightClick={handleRightClick}
              handleFirstPageClick={handleFirstPageClick}
              handleLastPageClick={handleLastPageClick}
              handlePageClick={handlePageChange}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ClientReport;
