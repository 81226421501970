import DownloadIcon from "@mui/icons-material/Download";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {
  Box,
  Card,
  Grid,
  IconButton,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import DatePicker from "app/components/Shared/DatePicker/DatePicker.component";
import dayjs from "dayjs";
import React, { useState } from "react";
import Chart from "react-apexcharts";

const ReportPage = () => {
  const ChartComponent = () => {
    const options = {
      series: [
        {
          name: "Successful Calls",
          data: [10, 41, 35, 51, 49, 91, 148],
        },
        {
          name: "Missed Calls",
          data: [20, 35, 45, 65, 25, 75, 55],
        },
        {
          name: "Failed Calls",
          data: [5, 35, 45, 55, 65, 15, 25],
        },
      ],
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
        width: 2,
      },
      grid: {
        row: {
          colors: ["#ffffff", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: ["Sun", "Mon", "Tue", "Wed", "Thurs", "Fri", "Sat"],
      },
      legend: {
        position: "right",
      },
      colors: ["#008000", "#D60000", "#FFD700"],
    };

    return (
      <Box
        width="100%"
        height="100%"
        maxWidth="950px"
        maxHeight="200px"
        sx={{
          flex: 1,
          "@media (max-width: 950px)": {
            maxWidth: "100%",
            maxHeight: "100%",
          },
        }}
      >
        <Chart
          options={options}
          series={options.series}
          type="line"
          height="100%"
          width="100%"
        />
      </Box>
    );
  };

  const ViewReport = ({ selectedRowData, handleClose }) => {
    const data = selectedRowData.row;

    const { tableData } = selectedRowData?.row;

    const columns = [
      { field: "id", headerName: "ID", hide: true },
      { field: "date", headerName: "Date/Time", flex: 1 },
      { field: "contact", headerName: "Contact", flex: 1 },
      { field: "attemptCalls", headerName: "Attempt Calls", flex: 1 },
      { field: "duration", headerName: "Duration", flex: 1 },
      { field: "status", headerName: "Status", flex: 1 },
    ];

    return (
      <Modal open={viewReport} onClose={handleClose}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          sx={{
            width: "70vw",
            height: "80vh",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            boxShadow: "1 4px 6px rgba(35, 35, 156, 0.1)",
            bgcolor: "background.paper",
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" sx={{ ml: 1, mb: 1 }}>
            {data.campaignName}
          </Typography>
          <DataGrid
            rows={tableData}
            columns={columns}
            autoHeight
            disableColumnMenu
            hideFooterPagination
            hideFooter
          />
        </Box>
      </Modal>
    );
  };

  const columns = [
    { field: "id", headerName: "ID", hide: true },
    { field: "campaignName", headerName: "Campaign Name", flex: 1 },
    { field: "lastBlastDate", headerName: "Last Blast Date", flex: 1 },
    { field: "successfulCalls", headerName: "Successful Calls", flex: 1 },
    { field: "missedCalls", headerName: "Missed Calls", flex: 1 },
    { field: "failedCalls", headerName: "Failed Calls", flex: 1 },
    { field: "attemptCalls", headerName: "Attempt Calls", flex: 1 },
    { field: "averageDuration", headerName: "Average Duration", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Tooltip title="View">
            <IconButton onClick={() => handleViewReport(rows)}>
              <VisibilityOutlinedIcon sx={{ color: "#001A72", fontSize: 20 }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Download">
            <IconButton>
              <DownloadIcon sx={{ color: "#001A72", fontSize: 20 }} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  const rows = [
    {
      id: 1,
      campaignName: "Holiday",
      lastBlastDate: "smth",
      successfulCalls: 10,
      missedCalls: 20,
      failedCalls: 5,
      attemptCalls: 35,
      averageDuration: "30 sec",
      tableData: [
        {
          id: 1,
          contact: "1234567890",
          status: "In Progress",
          duration: "Edit",
          attemptCalls: 35,
          date: "2024-03-05",
          time: "12:00:00",
        },
        {
          id: 2,
          contact: "2345678901",
          status: "Ringing",
          duration: "Delete",
          attemptCalls: 35,
          date: "2024-03-05",
          time: "12:00:00",
        },
        {
          id: 3,
          contact: "3456789012",
          status: "In Progress",
          duration: "Edit",
          attemptCalls: 35,
          date: "2024-03-05",
          time: "12:00:00",
        },
        {
          id: 4,
          contact: "4567890123",
          status: "Ringing",
          duration: "Delete",
          attemptCalls: 35,
          date: "2024-03-05",
          time: "12:00:00",
        },
        {
          id: 5,
          contact: "5678901234",
          status: "In Progress",
          duration: "Edit",
          attemptCalls: 35,
          date: "2024-03-05",
          time: "12:00:00",
        },
      ],
    },
    {
      id: 2,
      campaignName: "Greetings",
      lastBlastDate: "smth",
      successfulCalls: 10,
      missedCalls: 20,
      failedCalls: 5,
      attemptCalls: 35,
      averageDuration: "30 sec",
      tableData: [
        {
          id: 1,
          contact: "1234567890",
          status: "In Progress",
          duration: "Edit",
          attemptCalls: 35,
          date: "2024-03-05",
          time: "12:00:00",
        },
        {
          id: 2,
          contact: "2345678901",
          status: "Ringing",
          duration: "Delete",
          attemptCalls: 35,
          date: "2024-03-05",
          time: "12:00:00",
        },
        {
          id: 3,
          contact: "3456789012",
          status: "In Progress",
          duration: "Edit",
          attemptCalls: 35,
          date: "2024-03-05",
          time: "12:00:00",
        },
        {
          id: 4,
          contact: "4567890123",
          status: "Ringing",
          duration: "Delete",
          attemptCalls: 35,
          date: "2024-03-05",
          time: "12:00:00",
        },
        {
          id: 5,
          contact: "5678901234",
          status: "In Progress",
          duration: "Edit",
          attemptCalls: 35,
          date: "2024-03-05",
          time: "12:00:00",
        },
      ],
    },
    {
      id: 3,
      campaignName: "Holiday",
      lastBlastDate: "smth",
      successfulCalls: 10,
      missedCalls: 20,
      failedCalls: 5,
      attemptCalls: 35,
      averageDuration: "30 sec",
      tableData: [
        {
          id: 1,
          contact: "1234567890",
          status: "In Progress",
          duration: "Edit",
          attemptCalls: 35,
          date: "2024-03-05",
          time: "12:00:00",
        },
        {
          id: 2,
          contact: "2345678901",
          status: "Ringing",
          duration: "Delete",
          attemptCalls: 35,
          date: "2024-03-05",
          time: "12:00:00",
        },
      ],
    },
    {
      id: 4,
      campaignName: "Holiday",
      lastBlastDate: "smth",
      successfulCalls: 10,
      missedCalls: 20,
      failedCalls: 5,
      attemptCalls: 35,
      averageDuration: "30 sec",
      tableData: [
        {
          id: 1,
          contact: "1234567890",
          status: "In Progress",
          duration: "Edit",
          attemptCalls: 35,
          date: "2024-03-05",
          time: "12:00:00",
        },
        {
          id: 2,
          contact: "2345678901",
          status: "Ringing",
          duration: "Delete",
          attemptCalls: 35,
          date: "2024-03-05",
          time: "12:00:00",
        },
        {
          id: 3,
          contact: "3456789012",
          status: "In Progress",
          duration: "Edit",
          attemptCalls: 35,
          date: "2024-03-05",
          time: "12:00:00",
        },
      ],
    },
    {
      id: 5,
      campaignName: "Holiday",
      lastBlastDate: "smth",
      successfulCalls: 10,
      missedCalls: 20,
      failedCalls: 5,
      attemptCalls: 35,
      averageDuration: "30 sec",
      tableData: [
        {
          id: 1,
          contact: "1234567890",
          status: "In Progress",
          duration: "Edit",
          attemptCalls: 35,
          date: "2024-03-05",
          time: "12:00:00",
        },
        {
          id: 2,
          contact: "2345678901",
          status: "Ringing",
          duration: "Delete",
          attemptCalls: 35,
          date: "2024-03-05",
          time: "12:00:00",
        },
        {
          id: 3,
          contact: "3456789012",
          status: "In Progress",
          duration: "Edit",
          attemptCalls: 35,
          date: "2024-03-05",
          time: "12:00:00",
        },
      ],
    },
  ];

  const [viewReport, setViewReport] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);

  const [filterData, setFilterData] = useState({
    from: dayjs().subtract(7, "days").format("YYYY-MM-DD"),
    to: dayjs().format("YYYY-MM-DD"),
  });

  const handleFromDate = (value) => {
    setFilterData({
      ...filterData,
      from: dayjs(value).format("YYYY-MM-DD"),
    });
  };

  const handleToDate = (value) => {
    setFilterData({
      ...filterData,
      to: dayjs(value).format("YYYY-MM-DD"),
    });
  };

  const handleViewReport = (row) => {
    setSelectedRowData(row);
    setViewReport(true);
  };

  const handleClose = () => {
    setViewReport(false);
  };

  return (
    <Box
      sx={{
        height: "100vh",
        overflowY: "hidden",
        overflowX: "hidden",
        marginBottom: 2,
        mt: 1,
      }}
    >
      {viewReport && (
        <ViewReport
          selectedRowData={selectedRowData}
          handleClose={handleClose}
        />
      )}
      <Box sx={{ mb: 1 }}>
        <Typography variant="h6" sx={{ ml: 2 }}>
          Successful/ Missed/ Failed Calls
        </Typography>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="30vh"
        >
          <ChartComponent />
        </Box>
      </Box>
      <Box>
        <Grid container direction="row">
          <Box
            display={"flex"}
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            marginTop={1}
          >
            <Typography variant="h6" sx={{ ml: 2 }}>
              Call Blasting Report
            </Typography>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              sx={{ width: 350 }}
            >
              <Box sx={{ m: 1 }}>
                <DatePicker
                  label={"From"}
                  value={filterData?.from}
                  handleChange={handleFromDate}
                  name="from"
                />
              </Box>
              <Box sx={{ m: 1 }}>
                <DatePicker
                  label={"To"}
                  value={filterData?.to}
                  handleChange={handleToDate}
                  name="to"
                />
              </Box>
            </Box>
          </Box>
        </Grid>
        <Box
          sx={{
            height: "35vh",
            overflowY: "auto",
            overflowX: "hidden",
            marginBottom: 2,
            ml: 2,
            mr: 2,
            border: "1px solid #d3d3d3",
            borderRadius: 1,
            "&::-webkit-scrollbar": {
              width: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#ccc",
              borderRadius: "3px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "#aaa",
            },
          }}
        >
          <Box sx={{ mb: 5 }}>
            <DataGrid
              rows={rows}
              columns={columns}
              autoHeight
              disableColumnMenu
              hideFooterPagination
              hideFooter
              onRowClick={handleViewReport}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ReportPage;
