import PlaylistPlayIcon from "@mui/icons-material/PlaylistPlay";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  alpha,
} from "@mui/material";
import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { DataGrid } from "@mui/x-data-grid";
import DatePicker from "app/components/Shared/DatePicker/DatePicker.component";
import dayjs from "dayjs";
import React, { useRef, useState } from "react";

const AudioManagement = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const [filterData, setFilterData] = useState({
    from: dayjs().subtract(7, "days").format("YYYY-MM-DD"),
    to: dayjs().format("YYYY-MM-DD"),
  });

  const handleFromDate = (value) => {
    setFilterData({
      ...filterData,
      from: dayjs(value).format("YYYY-MM-DD"),
    });
  };

  const handleToDate = (value) => {
    setFilterData({
      ...filterData,
      to: dayjs(value).format("YYYY-MM-DD"),
    });
  };

  const card = (
    <React.Fragment>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justify: "center",
          alignItems: "center",
          paddingTop: "12px",
          paddingBottom: 0,
        }}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        {!selectedFile ? (
          <React.Fragment>
            <CardMedia
              component={"img"}
              sx={{ height: 40, width: 40 }}
              image="/feather_upload-cloud.png"
              alt="upload-icon"
            />
            <Typography variant="body2" sx={{ pb: 0.5 }}>
              Select a file or Drag and Drop Here
            </Typography>
          </React.Fragment>
        ) : (
          <Typography
            color="text.secondary"
            variant="body3"
            sx={{ mt: 5, pb: 0.5 }}
          >
            {selectedFile.name}
          </Typography>
        )}
      </CardContent>
      <CardActions
        sx={{
          display: "flex",
          justifyContent: "center",
          pt: 0.5,
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <input
          type="file"
          id="fileInput"
          style={{ display: "none" }}
          onChange={handleFileChange}
          ref={fileInputRef}
        />
        {!selectedFile ? (
          <label htmlFor="fileInput">
            <Button
              size="small"
              variant="contained"
              onClick={handleButtonClick}
              sx={{
                backgroundColor: "#23239C",
                boxShadow: "0 0 0 0.2px rgba(35, 35, 156, 0.7)",
                mb: 3,
                "&:hover": {
                  backgroundColor: alpha("#23239C", 0.85),
                },
              }}
            >
              <PlaylistPlayIcon />
              Browse
            </Button>
          </label>
        ) : (
          <Button
            size="small"
            variant="contained"
            sx={{
              backgroundColor: "#23239C",
              boxShadow: "0 0 0 0.2px rgba(35, 35, 156, 0.7)",
              mb: 3,
              "&:hover": {
                backgroundColor: alpha("#23239C", 0.85),
              },
            }}
          >
            Upload
          </Button>
        )}
      </CardActions>
    </React.Fragment>
  );

  const columns = [
    { field: "id", headerName: "ID", hide: true },
    { field: "file_name", headerName: "File Name", flex: 1 },
    { field: "file_size", headerName: "File Size", flex: 1 },
    { field: "duration", headerName: "Duration", flex: 1 },
    { field: "date", headerName: "Date", flex: 1 },
    {
      field: "action",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Tooltip title="Play">
            <IconButton>
              <PlayCircleFilledWhiteOutlinedIcon
                sx={{ color: "#001A72", fontSize: 20 }}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton>
              <DeleteOutlinedIcon sx={{ color: "#D60000", fontSize: 20 }} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  const rows = [
    {
      id: 1,
      file_name: "xyz.mp3",
      file_size: "99.1 KB",
      duration: "30 sec",
      date: "2021-10-10",
      action: "Play",
    },
    {
      id: 2,
      file_name: "xyz.mp3",
      file_size: "99.1 KB",
      duration: "30 sec",
      date: "2021-10-10",
      action: "Play",
    },
    {
      id: 3,
      file_name: "xyz.mp3",
      file_size: "99.1 KB",
      duration: "30 sec",
      date: "2021-10-10",
      action: "Play",
    },
    {
      id: 4,
      file_name: "xyz.mp3",
      file_size: "99.1 KB",
      duration: "30 sec",
      date: "2021-10-10",
      action: "Play",
    },
    {
      id: 5,
      file_name: "xyz.mp3",
      file_size: "99.1 KB",
      duration: "30 sec",
      date: "2021-10-10",
      action: "Play",
    },
    {
      id: 6,
      file_name: "xyz.mp3",
      file_size: "99.1 KB",
      duration: "30 sec",
      date: "2021-10-10",
      action: "Play",
    },
    {
      id: 7,
      file_name: "xyz.mp3",
      file_size: "99.1 KB",
      duration: "30 sec",
      date: "2021-10-10",
      action: "Playd",
    },
    {
      id: 8,
      file_name: "xyz.mp3",
      file_size: "99.1 KB",
      duration: "30 sec",
      date: "2021-10-10",
      action: "Playv",
    },
    {
      id: 9,
      file_name: "xyz.mp3",
      file_size: "99.1 KB",
      duration: "30 sec",
      date: "2021-10-10",
      action: "Playm",
    },
    {
      id: 10,
      file_name: "xyz.mp3",
      file_size: "99.1 KB",
      duration: "30 sec",
      date: "2021-10-10",
      action: "Playe",
    },
    {
      id: 11,
      file_name: "xyz.mp3",
      file_size: "99.1 KB",
      duration: "30 sec",
      date: "2021-10-10",
      action: "Plays",
    },
  ];

  return (
    <Box
      sx={{
        height: "100vh",
        overflowY: "hidden",
        overflowX: "hidden",
        marginBottom: 2,
        mt: 1,
      }}
    >
      <Box sx={{ mb: 1 }}>
        <Typography variant="h6" sx={{ ml: 2 }}>
          Upload Audio
        </Typography>
        <Box display={"flex"} sx={{ minWidth: 275, justifyContent: "center" }}>
          <Card
            variant="outlined"
            sx={{
              justifyContent: "center",
              backgroundColor: "rgba(35, 35, 156, 0.05)",
              height: "20vh",
              minWidth: 270,
            }}
          >
            {card}
          </Card>
        </Box>
      </Box>
      <Box>
        <Grid container direction="row">
          <Box
            display={"flex"}
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            marginTop={1}
          >
            <Typography variant="h6" sx={{ ml: 2 }}>
              Uploaded List
            </Typography>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              sx={{ width: 350 }}
            >
              <Box sx={{ m: 1 }}>
                <DatePicker
                  label={"From"}
                  value={filterData?.from}
                  handleChange={handleFromDate}
                  name="from"
                />
              </Box>
              <Box sx={{ m: 1 }}>
                <DatePicker
                  label={"To"}
                  value={filterData?.to}
                  handleChange={handleToDate}
                  name="to"
                />
              </Box>
            </Box>
          </Box>
        </Grid>
        <Box
          sx={{
            height: "45vh",
            overflowY: "auto",
            overflowX: "hidden",
            marginBottom: 2,
            ml: 2,
            mr: 2,
            border: "1px solid #d3d3d3",
            borderRadius: 1,
            "&::-webkit-scrollbar": {
              width: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#ccc",
              borderRadius: "3px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "#aaa",
            },
          }}
        >
          <Box sx={{ marginBottom: 5 }}>
            <DataGrid
              rows={rows}
              columns={columns}
              autoHeight
              disableColumnMenu
              hideFooterPagination
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AudioManagement;
