import React, { useState } from "react";
import {
  TextField,
  Select,
  MenuItem,
  Button,
  Box,
  InputLabel,
  FormControl,
} from "@mui/material";
import {
  Call,
  ConfirmationNumber,
  DoDisturb,
  SmartToy,
  Voicemail,
} from "@mui/icons-material";

const TicketReportFilter = ({
  onFiltersChange,
  filters,
  issueCategories,
  ticketStatus,
  ticketType,
  departmentList,
  onResetFilters,
}) => {
  const handleChange = (filterName) => (event) => {
    const updatedFilters = {
      ...filters,
      [filterName]: event.target.value,
    };

    onFiltersChange(updatedFilters);
  };
  const commonSx = (filterName) => ({
    width: filterName === "client" ? 240 : 150,
    marginRight: 2,
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: filters[filterName] ? "#1B1A55" : "#23239C",
        borderWidth: filters[filterName] ? 3 : 1.2,
        borderRadius: 4,
      },
      "&:hover fieldset": {
        borderColor: "primary.dark",
      },
      "&.Mui-focused fieldset": {
        borderColor: "primary.main",
      },
    },
    "&:last-child": {
      marginRight: 0,
    },
  });

  const getTicketTypeIcon = (typeName) => {
    switch (typeName) {
      case "Manual":
        return <ConfirmationNumber sx={{ color: "#23239C" }} />;
      case "Chat":
        return <SmartToy sx={{ color: "#23239C" }} />;
      case "Call":
        return <Call sx={{ color: "#23239C" }} />;
      case "Voicemail":
        return <Voicemail sx={{ color: "#23239C" }} />;
      default:
        return null;
    }
  };
  return (
    <>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        sx={{
          flexWrap: "wrap",
          marginTop: "1rem",
          width: "100%",
          gap: "8px",
          "& > *:not(:first-of-type)": {
            marginTop: { xs: "8px", sm: 0 },
          },
        }}
      >
        <FormControl size="small" sx={commonSx("department")}>
          <InputLabel id="department-select-label">Department</InputLabel>
          <Select
            labelId="department-select-label"
            value={filters?.department}
            onChange={handleChange("department")}
            label="Department"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {departmentList?.data?.map((dept) => (
              <MenuItem key={dept?._id} value={dept?._id}>
                {dept?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl size="small" sx={commonSx("status")}>
          <InputLabel id="source-select-label">Source</InputLabel>
          <Select
            labelId="source-select-label"
            value={filters?.status}
            onChange={handleChange("status")}
            label="Source"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {ticketStatus?.map((status) => (
              <MenuItem key={status._id} value={status._id}>
                {status.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl size="small" sx={commonSx("type")}>
          <InputLabel id="source-select-label">Ticket Type</InputLabel>
          <Select
            labelId="source-select-label"
            value={filters?.type}
            onChange={handleChange("type")}
            label="Ticket Type"
          >
            <MenuItem value="">
              <Box display="flex" flexDirection={"row"} gap={"10px"}>
                <DoDisturb sx={{ color: "#23239C" }} />
                <em>None</em>
              </Box>
            </MenuItem>
            {ticketType?.map((type) => (
              <MenuItem key={type._id} value={type._id}>
                <Box display="flex" flexDirection={"row"} gap={"10px"}>
                  {getTicketTypeIcon(type?.name)}
                  {type.name}
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          variant="outlined"
          size="small"
          label="Assigned User"
          type="text"
          value={filters?.assignedUser || ""}
          onChange={handleChange("assignedUser")}
          sx={commonSx("assignedUser")}
        />
        <TextField
          variant="outlined"
          size="small"
          label="From Date"
          type="date"
          value={filters?.fromDate}
          onChange={handleChange("from")}
          InputLabelProps={{ shrink: true }}
          sx={commonSx("from")}
        />
        <TextField
          variant="outlined"
          size="small"
          label="To Date"
          type="date"
          value={filters?.toDate}
          onChange={handleChange("to")}
          InputLabelProps={{ shrink: true }}
          sx={commonSx("to")}
        />
        <FormControl size="small" sx={commonSx}>
          <InputLabel id="status-select-label">Issue Category</InputLabel>
          <Select
            labelId="status-select-label"
            value={filters?.issue}
            onChange={handleChange("issue")}
            label="Issue Category"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {issueCategories?.map((category) => (
              <MenuItem key={category._id} value={category._id}>
                {category.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          variant="outlined"
          size="small"
          label="Client Number/Id/Email"
          value={filters?.client}
          onChange={handleChange("client")}
          sx={commonSx("client")}
        />
        <Button
          variant="outlined"
          onClick={() => onResetFilters()}
          sx={{
            color: "white",
            borderRadius: "10px",
            backgroundColor: "#23239C",
            m: 1,
          }}
        >
          Clear
        </Button>
      </Box>
    </>
  );
};

export default TicketReportFilter;
