import { Card, CardContent, Grid, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import TicketReport from "./report-tabs/TicketReport";
import ClientReport from "./report-tabs/ClientReport";

const TabPage = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (_, newTab) => {
    setActiveTab(newTab);
  };

  return (
    <Card
      sx={{
        height: "130vh",
        width: "100%",
        flexGrow: 1,
        borderRadius: "14px",
      }}
    >
      <CardContent>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              aria-label="tabs"
            >
              {tabs.map((tab, index) => (
                <Tab
                  key={index}
                  label={tab.label}
                  style={{ flexGrow: 1, flexBasis: 0 }}
                />
              ))}
            </Tabs>
          </Grid>
          <Grid item xs={12}>
            {tabs[activeTab].component}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const ReportComponent = () => {
  const tabs = [
    { label: "Client Report", component: <ClientReport />},
    { label: "Agent Report" },
    { label: "Ticket Report", component: <TicketReport /> },
    { label: "CDR Report" },
  ];

  return <TabPage tabs={tabs} />;
};

export default ReportComponent;
