import { TextField } from "@mui/material";
import React from "react";

const CommentAction = ({ duringCallValue, handleCommentChange }) => {
  return (
    <>
      <TextField
        size="medium"
        value={duringCallValue?.comment}
        label="Write Comment Here ..."
        multiline
        maxRows={1}
        name="comment"
        fullWidth
        onChange={handleCommentChange}
        InputProps={{
          sx: {
            borderRadius: "12px",
            backgroundColor: "#F5F5F5",
          },
          disableUnderline: true,
        }}
      />
    </>
  );
};

export default CommentAction;
