import React from "react";
import { Box } from "@mui/material";
import ReactApexCharts from "react-apexcharts";

// custom progress bar to show the progress data and percentage
// used in the TicketReport component
const ProgressBar = ({ name, progressData, barColor, progressPercent }) => {
  return (
    <Box id={`progress-${name}`} style={{ marginBottom: "20px" }}>
      <ReactApexCharts
        options={{
          chart: {
            height: 50,
            type: "bar",
            stacked: true,
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
              barHeight: "20%",
              colors: {
                backgroundBarColors: ["#EEEEEE"],
              },
              borderRadius: 3,
            },
          },
          stroke: {
            width: 0,
          },
          title: {
            floating: true,
            offsetY: 0,
            text: `${name}  -  ${progressData} `,
            style: {
              fontSize: "16px",
              fontWeight: "normal",
            },
          },
          subtitle: {
            floating: true,
            align: "right",
            offsetY: 0, 
            text: `${progressPercent}%`,
            style: {
              fontSize: "15px",
              fontWeight: "bold",
              color: barColor,
            },
          },
          subtitle2: {
            floating: true,
            offsetY: 0,
            text: "New Subtitle",
            style: {
              fontSize: "14px",
              fontWeight: "normal",
              color: "#333333",
            },
          },
          tooltip: {
            enabled: false,
          },
          xaxis: {
            categories: [name],
          },
          yaxis: {
            max: 100,
          },
          fill: {
            opacity: 1,
          },
        }}
        series={[
          {
            name: name,
            data: [progressPercent],
            color: barColor,
          },
        ]}
        type="bar"
        height={50}
        width={"95%"}
      />
    </Box>
  );
};

export default ProgressBar;
