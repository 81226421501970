import { TextField, alpha } from "@mui/material";

const TimePickerComponent = ({
  label,
  value,
  handleChange,
  name,
  disabled,
}) => {
  return (
    <TextField
      label={label}
      value={value}
      name={name}
      onChange={handleChange}
      variant="outlined"
      fullWidth
      size="small"
      disabled={disabled}
      sx={{
        "& .MuiOutlinedInput-root": {
          borderRadius: 5,
        },
        "& .MuiOutlinedInput-input.Mui-disabled": {
          WebkitTextFillColor: "rgba(19, 19, 19, 0.8)",
        },
        "& .MuiInputLabel-root.Mui-disabled": {
          color: "rgba(19, 19, 19, 0.7)",
        },
        "& .MuiOutlinedInput-root.Mui-disabled.MuiOutlinedInput-notchedOutline":
          {
            borderColor: "rgba(19, 19, 19, 0.5)",
          },
      }}
      inputProps={{
        name: label,
        type: "time",
        step: 300,
      }}
    />
  );
};

export default TimePickerComponent;
