//Admin_Ticket_List

export const adminTicketList = {
  ticket_list: "/admin/report/ticket",
  assigned_to_me: "/admin/report/assigned-ticket",
};

//Call-history-Report

export const adminCallHistoryReport = {
  callhistory_list: "admin/report/call",
};

//Hold_time_report

export const adminQueueReport = {
  Queue_report: "admin/report/queue",
};

//all-queue-list

export const allQueueList = {
  allqueue_list: "admin/queues",
};

export const singleQueue = {
  single_queue: "admin/queue",
};

// Break Detail Report

export const adminBreakReport = {
  break_report: "extension/get-break-report",
};

//Queue_report

export const adminqueueReport = {
  queue_report: "/queue/report",
};

//extension_List

export const extensionList = {
  extension_list: "admin/users",
};

//all Category-list

export const allcategoryList = {
  category_list: "admin/issue",
};

//all sub category list

export const allsubcategoryList = {
  sub_category_list: "category/sub-issue",
};

// update ticket //TODO change agent to

export const updateTicket = {
  update_ticket: "agent/ticket",
};

export const updateAdminTicket = {
  update_Admin_ticket: "admin/ticket",
};

export const admindashboardStats = {
  dashboard_stats: "admin/stats/dashboard",
};

export const addIssueCategory = {
  issue_category: "admin/issue",
};

export const addSubIssueCategory = {
  Sub_issue_category: "admin/subissue",
};

//to listen recording
export const listenRecording = {
  listen_recording: "admin/recording/call",
};

export const adminlistenVoicemail = {
  admin_voicemail_recording: "admin/recording/voicemail",
};

//to fetch all agent

export const getAllAgent = {
  get_all_agent: "/admin/agents",
};

//to fetch email template for setting

export const getEmailTemplate = {
  get_email_template: "/admin/template",
};

export const queueCallLogs = {
  queue_call_logs: "admin/report/queue-logs",
};

export const breakCategory = {
  break_category: "admin/breaks",
  extension_pause: "admin/break",
  extension_status: "admin/extension-status",
};

// outbound campaign
//TODO api/v1 and no auth implemenatation

export const addregistercampaign = {
  add_register_campaign: "/outbound-campaign/register",
};

export const getregisterCampaignByIdForTable = {
  get_register_CampaignBy_Id_ForTable: "/outbound-campaign/registered-campaign",
};

export const uploadLeeds = {
  upload_leeds: "/outbound-campaign/add-clients",
};

export const getRegisteredClients = {
  get_registered_client: "/outbound-campaign/client-list",
};

export const assignAgentExtension = {
  assign_Agent: "/outbound-campaign/assign-extension",
};

//to listen recording for ticket
export const adminlistenRecording = {
  admin_listen_recording: "admin/recording/ticket",
};

//to update ticet status,remarks
export const adminupdateTicketStatus = {
  admin_update_ticket_status: "admin/solve-ticket",
};

//version 2 ticket
export const adminTicketV2 = {
  admin_ticket_v2: "admin/v2/report/ticket",
};

export const getTicketbyIdV2 = {
  Admin_get_ticket_by_id: "admin/v2/ticket",
};
export const admingetAllticketType = {
  admin_get_all_ticket_type: "admin/v2/ticket/types",
};

export const createTicketAdmin = {
  admin_create_ticket: "admin/v2/ticket",
};
export const addCommentTicketPageAdmin = {
  admin_add_comment: "admin/v2/ticket/comment",
};
export const adminGetStatusTypes = {
  admin_get_status_types: "admin/v2/status-types",
};

export const getAllDepartmentAdmin = {
  admin_get_all_department: "admin/v2/department",
};

export const adminGetTicketByPhone = {
  admin_get_ticket_by_phone: "admin/v2/ticket/phone",
};

export const adminGetallNotifications = {
  admin_get_all_notifications: "admin/push-notifications",
};

export const adminReadNotifications = {
  admin_read_notification: "admin/read-notifications",
};

export const adminAssignTicket = {
  admin_assigned_ticket: "admin/assign-ticket",
};

export const adminAssignTicketDepartment = {
  admin_assigned_ticket_department: "admin/assign-ticket-department",
};

export const admindownloadTickets = {
  admin_download_tickets: "admin/download/report/ticket",
};

export const adminPickTicketApi = {
  admin_pick_ticket_api: "admin/ticket/pick-ticket",
};

export const adminBulkTickets = {
  admin_bulk_tickets: "admin/bulk-ticket",
};

export const adminReportStatsTicketReport = {
  admin_report_stats_ticket_report: "admin/v2/stats/ticket",
};

export const adminReportStatsClientReport = {
  admin_report_stats_client_report: "admin/v2/stats/client",
};

export const adminReportStatsTicketExport = {
  admin_report_stats_ticket_export_csv: "admin/v2/download/report/ticket",
};

export const adminReportStatsClientExport = {
  admin_report_stats_client_export_csv: "admin/v2/download/report/client",
};

export const adminGetUserDetails = {
  admin_get_user_details: "admin/user-profile",
};
