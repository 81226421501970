import React, { useEffect, useState } from "react";
import TicketReportFilter from "./FilterComponent";
import CardComponent from "./CardComponent";
import { useQuery } from "@tanstack/react-query";
import { instance } from "app/utils/axiosRequest";
import qs from "qs";
import {
  adminGetStatusTypes,
  adminGetUserDetails,
  adminTicketV2,
  admingetAllticketType,
  allcategoryList,
  getAllDepartmentAdmin,
} from "app/utils/api-urls/AdminAPiUrls";
import {
  agentGetStatusTypes,
  agentGetUserDetails,
  agentTicketV2,
  agentcategoryList,
  agentgetAllticketType,
  getAllDepartmentAgent,
} from "app/utils/api-urls/AgentApiUrls";
import {
  getAllDepartmentSupervisor,
  getAllticketTypeSupervisor,
  supervisorGetStatusTypes,
  supervisorGetUserDetails,
  supervisorTicketV2,
  supervisorallcategoryList,
} from "app/utils/api-urls/SupervisorAPiUrls";
import FullpageLoader from "app/components/FullpageLoader/FullpageLoader";
import CustomPagination from "app/shared/Pagination/Pagination.component";
import { Box } from "@mui/material";

const TicketReportV2 = () => {
  const [filtersData, setFiltersData] = useState({
    from: "",
    to: "",
    status: "",
    issue: "",
    subissue: "",
    status: "",
    page: 1,
    limit: 20,
    type: "",
    client: "",
    department: "",
    assignedUser: "",
  });

  const handleFiltersChange = (updatedFilters) => {
    setFiltersData((prevState) => ({
      ...prevState,
      ...updatedFilters,
    }));
  };

  const applyFilters = () => {
    setFiltersData((prevState) => ({
      ...prevState,
      page: 1,
    }));
    refetch();
  };

  const roleBasedUrls = {
    agent: {
      getallTickets: agentTicketV2.agent_ticket_v2,
      getallStatus: agentGetStatusTypes.agent_get_status_types,
      category: agentcategoryList.agent_category_list,
      ticketType: agentgetAllticketType.agent_get_all_ticket_type,
      departmentList: getAllDepartmentAgent.agent_get_all_department,
      userinfo: agentGetUserDetails.agent_get_user_details,
    },
    admin: {
      getallTickets: adminTicketV2.admin_ticket_v2,
      getallStatus: adminGetStatusTypes.admin_get_status_types,
      category: allcategoryList.category_list,
      ticketType: admingetAllticketType.admin_get_all_ticket_type,
      departmentList: getAllDepartmentAdmin.admin_get_all_department,
      userinfo: adminGetUserDetails.admin_get_user_details,
    },
    supervisor: {
      getallTickets: supervisorTicketV2.supervisor_ticket_v2,
      getallStatus: supervisorGetStatusTypes.supervisor_get_status_types,
      category: supervisorallcategoryList.supervisor_category_list,
      ticketType: getAllticketTypeSupervisor.supervisor_get_all_ticket_type,
      departmentList: getAllDepartmentSupervisor.supervisor_get_all_department,
      userinfo: supervisorGetUserDetails.supervisor_get_user_details,
    },
  };

  const role = localStorage.getItem("role");

  const urls = roleBasedUrls[role] || roleBasedUrls.admin;

  const { data, refetch, isLoading, isRefetching } = useQuery(
    ["ticket-report-v2", filtersData],
    () => instance.get(`${urls.getallTickets}?${qs.stringify(filtersData)}`)
  );
  const { data: issueCategory, isLoading: issueCategoryLoading } = useQuery(
    ["issueCategories"],
    () => instance.get(urls.category)
  );

  const { data: ticketStatusData, isLoading: ticketStatusLoading } = useQuery(
    ["ticket-status"],
    () => instance.get(urls.getallStatus)
  );
  const { data: ticketTypeData, isLoading: ticketDataType } = useQuery(
    ["ticket-type"],
    () => instance.get(urls.ticketType)
  );
  const { data: allDepartmentData } = useQuery(["Department-list"], () =>
    instance.get(urls.departmentList)
  );
  const { data: userInfoData } = useQuery(["user-info"], () =>
    instance.get(urls.userinfo)
  );

  useEffect(() => {
    if (userInfoData?.data) {
      setFiltersData((prevState) => ({
        ...prevState,
        department: userInfoData?.data?.departments[0]?._id,
      }));
    }
  }, [userInfoData]);

  const handleLeftClick = () => {
    setFiltersData({
      ...filtersData,
      page: --filtersData.page,
    });
    refetch();
  };
  //pagination right click
  const handleRightClick = () => {
    setFiltersData({
      ...filtersData,
      page: ++filtersData.page,
    });
    refetch();
  };

  const handleFirstPageClick = () => {
    setFiltersData({
      ...filtersData,
      page: 1,
    });
    refetch();
  };

  const handleLastPageClick = () => {
    setFiltersData({
      ...filtersData,
      page: data?.data?.totalPages,
    });
    refetch();
  };

  const handlePageClick = (page) => {
    setFiltersData((prevState) => ({
      ...prevState,
      page: page,
    }));
    refetch();
  };

  const handleClearFilters = () => {
    setFiltersData({
      from: "",
      to: "",
      status: "",
      issue: "",
      subissue: "",
      status: "",
      page: 1,
      limit: 20,
      type: "",
      client: "",
      department: "",
      assignedUser: "",
    });
  };

  return (
    <>
      <FullpageLoader
        open={
          isLoading ||
          isRefetching ||
          issueCategoryLoading ||
          ticketStatusLoading
        }
      />
      <TicketReportFilter
        onFiltersChange={handleFiltersChange}
        filters={filtersData}
        issueCategories={issueCategory?.data}
        ticketStatus={ticketStatusData?.data?.data}
        ticketType={ticketTypeData?.data}
        departmentList={allDepartmentData?.data}
        onResetFilters={handleClearFilters}
        onApplyFilters={applyFilters}
      />
      <CardComponent cardData={data?.data} />

      {data?.data?.totalPages > 1 && (
        <>
          <Box marginTop="1rem">
            <CustomPagination
              currentPage={filtersData?.page}
              totalPage={data?.data?.totalPages}
              handleLeftClick={handleLeftClick}
              handleRightClick={handleRightClick}
              handleFirstPageClick={handleFirstPageClick}
              handleLastPageClick={handleLastPageClick}
              handlePageClick={handlePageClick}
            />
          </Box>
        </>
      )}
    </>
  );
};

export default TicketReportV2;
