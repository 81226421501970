import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import DatePicker from "app/components/Shared/DatePicker/DatePicker.component";
import SelectComponent from "app/components/Shared/Select/Select.component";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import { useState } from "react";
import dayjs from "dayjs";
import CreateCampaign from "./campaign-management/CreateCampaign";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

const CampaignManagement = () => {
  const [filterData, setFilterData] = useState({
    from: dayjs().subtract(7, "days").format("YYYY-MM-DD"),
    to: dayjs().format("YYYY-MM-DD"),
  });
  const [showCreateCampaignTab, setShowCreateCampaignTab] = useState(false);
  const [mode, setMode] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedCampaign, setSelectedCampaign] = useState(null);

  const columns = [
    { field: "id", headerName: "ID", hide: true },
    { field: "campaignName", headerName: "Campaign Name", flex: 1 },
    { field: "fromDate", headerName: "Created Date", flex: 0 },
    { field: "toDate", headerName: "Ending Date", flex: 0 },
    { field: "audio", headerName: "Audio", flex: 1 },
    { field: "trunk", headerName: "Trunk", flex: 1 },
    {
      field: "status",
      headerName: "Status",
      flex: 0,
      renderCell: (params) => (
        <Typography
          variant="body2"
          sx={{
            color: params.value === "Running" ? "#008000" : "#FF0000",
          }}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Tooltip title="View">
            <IconButton onClick={handleViewCampaignClick}>
              <VisibilityOutlinedIcon sx={{ color: "#001A72", fontSize: 20 }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit">
            <IconButton onClick={handleEditCampaignClick}>
              <EditOutlinedIcon sx={{ color: "#001A72", fontSize: 20 }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton onClick={handleDeleteCampaignClick}>
              <DeleteOutlinedIcon sx={{ color: "#D60000", fontSize: 20 }} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  const rows = [
    {
      id: 1,
      campaignName: "Example Campaign",
      fromDate: "2024-02-29",
      toDate: "2024-03-05",
      fromTime: "08:00",
      toTime: "17:00",
      trunk: "Example Trunk",
      callAttempt: 3,
      timeInterval: 10,
      ringTimeout: 30,
      status: "Running",
      audio: "Example Audio",
    },
    {
      id: 2,
      campaignName: "Example Campaign",
      fromDate: "2024-02-29",
      toDate: "2024-03-05",
      fromTime: "08:00",
      toTime: "17:00",
      trunk: "Example Trunk",
      callAttempt: 3,
      timeInterval: 10,
      ringTimeout: 30,
      status: "Pause",
      audio: "Example Audio",
    },
    {
      id: 3,
      campaignName: "Example 24",
      fromDate: "2024-02-29",
      toDate: "2024-03-05",
      fromTime: "08:00",
      toTime: "17:00",
      trunk: "Example Trunk",
      callAttempt: 3,
      timeInterval: 10,
      ringTimeout: 30,
      status: "Pause",
      audio: "Example Audio",
    },
    {
      id: 4,
      campaignName: "Example Campaign",
      fromDate: "2024-02-29",
      toDate: "2024-03-05",
      fromTime: "08:00",
      toTime: "07:00",
      trunk: "Example Trunk",
      callAttempt: 3,
      timeInterval: 10,
      ringTimeout: 30,
      status: "Running",
      audio: "Example Audio",
    },
    {
      id: 5,
      campaignName: "Example Campaign",
      fromDate: "2024-02-29",
      toDate: "2024-12-05",
      fromTime: "08:00",
      toTime: "17:00",
      trunk: "Example Trunk",
      callAttempt: 3,
      timeInterval: 10,
      ringTimeout: 30,
      status: "Running",
      audio: "Example Audio",
    },
    {
      id: 6,
      campaignName: "Example Campaign",
      fromDate: "2024-02-29",
      toDate: "2024-03-05",
      fromTime: "08:00",
      toTime: "17:00",
      trunk: "Example Trunk",
      callAttempt: 3,
      timeInterval: 10,
      ringTimeout: 30,
      status: "Pause",
      audio: "Example bv",
    },
    {
      id: 7,
      campaignName: "Example 45",
      fromDate: "2024-02-29",
      toDate: "2024-03-05",
      fromTime: "08:00",
      toTime: "17:00",
      trunk: "Example Trunk",
      callAttempt: 3,
      timeInterval: 10,
      ringTimeout: 30,
      status: "Running",
      audio: "Example Audio",
    },
    {
      id: 8,
      campaignName: "Campaign",
      fromDate: "2024-02-29",
      toDate: "2024-03-05",
      fromTime: "08:00",
      toTime: "17:00",
      trunk: "Holiday Greeting",
      callAttempt: 3,
      timeInterval: 10,
      ringTimeout: 30,
      status: "Running",
      audio: "Example Audio",
    },
    {
      id: 9,
      campaignName: "Campaign",
      fromDate: "2024-02-29",
      toDate: "2024-03-05",
      fromTime: "08:00",
      toTime: "17:00",
      trunk: "Holiday Greeting",
      callAttempt: 3,
      timeInterval: 10,
      ringTimeout: 30,
      status: "Running",
      audio: "Example Audio",
    },
    {
      id: 10,
      campaignName: "Campaign",
      fromDate: "2024-02-29",
      toDate: "2024-03-05",
      fromTime: "08:00",
      toTime: "17:00",
      trunk: "Holiday Greeting",
      callAttempt: 3,
      timeInterval: 10,
      ringTimeout: 30,
      status: "Running",
      audio: "Example Audio",
    },
    {
      id: 11,
      campaignName: "Campaign",
      fromDate: "2024-02-29",
      toDate: "2024-03-05",
      fromTime: "08:00",
      toTime: "17:00",
      trunk: "Holiday Greeting",
      callAttempt: 3,
      timeInterval: 10,
      ringTimeout: 30,
      status: "Running",
      audio: "Example Audio",
    },
  ];

  const filterOptions = [
    {
      id: 1,
      value: "all",
      label: "All",
    },
    {
      id: 2,
      value: "holiday-greeting",
      label: "Holiday Greeting",
    },
    {
      id: 3,
      value: "new-year",
      label: "New Year",
    },
    {
      id: 4,
      value: "lorem-ipsum",
      label: "Lorem Ipsum",
    },
    {
      id: 5,
      value: "lorem-ipsum",
      label: "Lorem Ipsum",
    },
    {
      id: 6,
      value: "lorem-ipsum",
      label: "Lorem Ipsum",
    },
  ];

  const handleFromDate = (value) => {
    setFilterData({
      ...filterData,
      from: dayjs(value).format("YYYY-MM-DD"),
    });
  };

  const handleToDate = (value) => {
    setFilterData({
      ...filterData,
      to: dayjs(value).format("YYYY-MM-DD"),
    });
  };

  const handleCreateCampaignClick = () => {
    setShowCreateCampaignTab(true);
    setSelectedRow(null);
    setMode("create");
  };

  const handleEditCampaignClick = (campaign) => {
    setShowCreateCampaignTab(true);
    setSelectedCampaign(campaign);
    setMode("edit");
  };

  const handleViewCampaignClick = (campaign) => {
    setSelectedCampaign(campaign);
    setShowCreateCampaignTab(true);
    setMode("view");
  };

  const handleDeleteCampaignClick = () => {
    console.log("Campaign Deleted");
  };

  const handleBack = () => {
    setShowCreateCampaignTab(false);
  };

  return (
    <Box sx={{ height: "100vh", marginBottom: 2 }}>
      {showCreateCampaignTab ? (
        <CreateCampaign
          campaign={selectedRow}
          onBack={handleBack}
          mode={mode}
        />
      ) : (
        <Box>
          <Box
            display={"flex"}
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6" sx={{ mb: 1, ml: 2 }}>
              Campaign Managenent
            </Typography>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              sx={{ justifyItems: "center" }}
            >
              <Box sx={{ m: 1, minWidth: 60 }}>
                <SelectComponent options={filterOptions} label="All" />
              </Box>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                sx={{ width: 350 }}
              >
                <Box sx={{ m: 1 }}>
                  <DatePicker
                    label={"From"}
                    value={filterData?.from}
                    handleChange={handleFromDate}
                    name="from"
                  />
                </Box>
                <Box sx={{ m: 1 }}>
                  <DatePicker
                    label={"To"}
                    value={filterData?.to}
                    handleChange={handleToDate}
                    name="to"
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  mt: 1.5,
                  mb: 1,
                  mr: 2,
                  ml: 0.5,
                  cursor: "pointer",
                }}
                onClick={handleCreateCampaignClick}
              >
                <ControlPointOutlinedIcon
                  sx={{ width: 30, height: 30, color: "#23239C" }}
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              height: "70vh",
              overflowY: "auto",
              overflowX: "hidden",
              mt: 2,
              ml: 2,
              mr: 2,
              border: "1px solid #d3d3d3",
              borderRadius: 1,
              "&::-webkit-scrollbar": {
                height: "6px",
                width: "6px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#ccc",
                borderRadius: "3px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: "#aaa",
              },
              boxShadow: "0px 0px 1px 0px #ccc",
            }}
          >
            <Box>
              <DataGrid
                columns={columns}
                rows={rows}
                autoHeight
                disableColumnMenu
                hideFooterPagination
                hideFooter
                onRowClick={(params) => setSelectedRow(params.row)}
              />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CampaignManagement;
