import { Card, CardContent, Grid, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import AudioManagement from "./Ticket-Report-Tabs/AudioManagement";
import CampaignManagement from "./Ticket-Report-Tabs/CampaignManagement";
import ReportPage from "./Ticket-Report-Tabs/ReportPage";
import FileManagement from "./Ticket-Report-Tabs/FileManagement";

const TabPage = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (_, newTab) => {
    setActiveTab(newTab);
  };

  return (
    <Card
      sx={{
        height: "100vh",
        width: "100%",
        // boxShadow: "4px 2px 24px rgba(35, 35, 156, 0.2)",
        borderRadius: "14px",
      }}
    >
      <CardContent>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              aria-label="tabs"
            >
              {tabs.map((tab, index) => (
                <Tab
                  key={index}
                  label={tab.label}
                  style={{ flexGrow: 1, flexBasis: 0 }}
                />
              ))}
            </Tabs>
          </Grid>
          <Grid item xs={12}>
            {tabs[activeTab].component}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const ReportsTabComponent = () => {
  const tabs = [
    { label: "Audio Management", component: <AudioManagement /> },
    { label: "Campaign Management", component: <CampaignManagement /> },
    { label: "File Management", component: <FileManagement /> },
    { label: "Report Page", component: <ReportPage /> },
  ];

  return <TabPage tabs={tabs} />;
};

export default ReportsTabComponent;
