import { lazy } from "react";
import callHistory from "app/pages/AdminDashboard/callHistory";
import IssueReports from "app/pages/reports/issue-reports/IssueReports";
import HoldTimeReport from "app/pages/reports/hold-time/HoldTimeReport";
import BreakDetails from "app/pages/reports/Break-detail/BreakDetails";
import LogReport from "app/pages/reports/Log-Report";
import HourlyReport from "app/pages/reports/Hourly-reports";
import TotalLiveAgent from "app/pages/reports/TotalLiveagent/TotalLiveAgent";
import AgentSummaryReport from "app/pages/AdminDashboard/agentSummary";
import ChatboxSection from "app/components/Chatbox";
import QueueCallLogs from "app/pages/reports/Queue-call-logs";
import AllReports from "app/pages/reports/all-reports";
import ReportStats from "app/pages/reports/report-stats";

const adminDashboardRoutes = [
  {
    path: "/app/admin/call-history",
    shortPath: "/admin/call-history",
    name: "Call-logs Report",
    component: callHistory,
    icon: "mdi mdi-phone-log",
  },
  // {
  //   path: "/app/admin/agent-summary",
  //   shortPath: "/admin/agent-summary",
  //   name: "Agent Summary",
  //   component: AgentSummaryReport,
  //   icon: "mdi mdi-face-agent",
  // },

  // {
  //   path: "/app/admin/report",
  //   shortPath: "/admin/report",
  //   name: "Reports",
  //   dropDownState: "reportOpen",

  //   dropDownMenuChilds: reportRoutes,
  //   icon: "chart-bar",
  //   auth: [],
  // },
  // {
  //   path: "/app/report/vip-access",
  //   shortPath: "/report/vip-access",
  //   name: "VIP Access",
  //   dropDownState: "vipAccessOpen",
  //   icon: "mdi mdi-account-lock",
  //   component: VipAccessReports,
  //   auth: [],
  // },
  // {
  //   path: "/app/admin/issue-reports",
  //   shortPath: "/admin/issue-reports",
  //   name: "Issue Reports",
  //   icon: "mdi mdi-chart-bar",
  //   component: IssueReports,
  //   hideInSidebar: true,
  // },
  // {
  //   path: "/app/admin/issue-reports-detail",
  //   shortPath: "/admin/issue-reports-detail",
  //   name: "Issue Reports Detail",
  //   icon: "mdi mdi-chart-bar",
  //   component: IssueReportDetail,
  //   hideInSidebar: true,
  // },
  // {
  //   path: "/app/admin/user-missed-detail",
  //   shortPath: "/admin/user-missed-detail",
  //   name: "User Missed Detail",
  //   icon: "mdi mdi-chart-bar",
  //   component: UserMissedDetail,
  //   hideInSidebar: true,
  // },
  {
    path: "/app/admin/queue-report",
    shortPath: "/admin/queue-report",
    name: "Queue Summary",
    icon: "mdi mdi-phone-paused",
    component: HoldTimeReport,
    hideInSidebar: false,
  },
  {
    path: "/app/admin/queue-log-report",
    shortPath: "/admin/queue-log-repport",
    name: "Queue-logs Report",
    icon: "mdi mdi-phone-paused",
    component: QueueCallLogs,
    hideInSidebar: false,
  },
  // {
  //   path: "/app/admin/break-detail",
  //   shortPath: "/admin/break-detail",
  //   name: "Break Detail",
  //   icon: "mdi mdi-account-clock",
  //   component: BreakDetails,
  //   hideInSidebar: false,
  // },
  // {
  //   path: "/app/admin/log-report",
  //   shortPath: "/admin/log-report",
  //   name: "Log Reports",
  //   icon: "mdi mdi-account-badge",
  //   component: LogReport,
  //   hideInSidebar: false,
  // },

  // {
  //   path: "/app/admin/hourly-report",
  //   shortPath: "/admin/hourly-report",
  //   name: "Hourly Reports",
  //   icon: "mdi mdi-clock-outline",
  //   component: HourlyReport,
  //   hideInSidebar: false,
  // },

  {
    path: "/app/admin/agent-status",
    shortPath: "/admin/agent-status",
    name: "Agent Status",
    icon: "mdi mdi-clock-outline",
    component: TotalLiveAgent,
  },

  {
    path: "/app/admin/chat-bot",
    shortPath: "/admin/chat-bot",
    name: "Chatbot",
    icon: "mdi mdi-clock-outline",
    component: ChatboxSection,
    hideInSidebar: true,
  },
  {
    path: "/app/admin/all-reports",
    shortPath: "/admin/all-reports",
    name: "All Reports",
    icon: "mdi mdi-clock-outline",
    component: AllReports,
  },
  {
    path: "/app/admin/report-stats",
    shortPath: "/admin/report-stats",
    name: "Report Stats",
    icon: "mdi mdi-clock-outline",
    component: ReportStats,
  },

  // {
  //   path: "/app/admin/FirebaseIntegration",
  //   shortPath: "/admin/FirebaseIntegration",
  //   name: "Firebase Demo",
  //   icon: "mdi mdi-clock-outline",
  //   component: FIrebaseDemo,
  //   hideInSidebar: false,
  // },
];

export default adminDashboardRoutes;
