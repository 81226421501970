import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CustomPagination from "app/shared/Pagination/Pagination.component";
import React, { useState } from "react";

const columns = [
  { field: "contact", headerName: "Contact Number", flex: 1 },
  {
    field: "status",
    headerName: "Status",
    flex: 0,
    renderCell: (params) => (
      <Typography
        variant="body2"
        sx={{
          color: params.value === "In Progress" ? "#FFD700" : "#0000FF",
        }}
      >
        {params.value}
      </Typography>
    ),
  },
  { field: "duration", headerName: "Duration", flex: 1 },
];
const allRows = [
  { id: 1, contact: "1234567890", status: "In Progress", duration: "Edit" },
  { id: 2, contact: "2345678901", status: "Ringing", duration: "Delete" },
  { id: 3, contact: "3456789012", status: "In Progress", duration: "Edit" },
  { id: 4, contact: "4567890123", status: "Ringing", duration: "Delete" },
  { id: 5, contact: "5678901234", status: "In Progress", duration: "Edit" },
  { id: 6, contact: "6789012345", status: "Ringing", duration: "Delete" },
  { id: 7, contact: "6789012345", status: "Ringing", duration: "Delete" },
  { id: 8, contact: "6789012345", status: "Ringing", duration: "Delete" },
  { id: 9, contact: "6789012345", status: "Ringing", duration: "Delete" },
  { id: 10, contact: "6789012345", status: "Ringing", duration: "Delete" },
  { id: 11, contact: "6789012345", status: "Ringing", duration: "Delete" },
  { id: 12, contact: "6789012345", status: "Ringing", duration: "Delete" },
];

const LiveCallsTableComponent = ({ tableData, data }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleFirstPageClick = () => {
    setCurrentPage(1);
  };

  const handleLastPageClick = () => {
    if (tableData.length > 0) {
      setCurrentPage(Math.ceil(tableData.length / 10));
    }
  };

  const handleLeftClick = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleRightClick = () => {
    setCurrentPage((prevPage) =>
      Math.min(prevPage + 1, Math.ceil(tableData.length / 10))
    );
  };

  const startIndex = (currentPage - 1) * 10;
  const endIndex = Math.min(startIndex + 10, tableData.length);
  const rows = tableData ? tableData.slice(startIndex, endIndex) : [];

  return (
    <Card
      sx={{
        height: "75.5vh",
        // boxShadow: "4px 2px 24px rgba(35, 35, 156, 0.2)",
        borderRadius: "14px",
      }}
    >
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h6" sx={{ ml: 2, mt: 1 }}>
              Live Calls
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              fontSize={18}
              fontWeight={400}
              display={"flex"}
              flexDirection={"column"}
              alignItems={"flex-end"}
              sx={{ mr: 2, mt: 1 }}
            >
              {data.campaignName}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography
              variant="body2"
              sx={{ ml: 2, py: 0.5, color: "#FFD700" }}
            >
              In progress : {data.inProgress}
            </Typography>
            <Typography
              variant="body2"
              sx={{ ml: 2, py: 0.5, color: "#0000FF", mb: 1 }}
            >
              Total : {data.total}
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"flex-end"}
          >
            <Typography
              variant="body2"
              sx={{ mr: 2, py: 0.5, color: "#008000" }}
            >
              Completed : {data.completed}
            </Typography>
            <Typography
              variant="body2"
              sx={{ mr: 2, py: 0.5, color: "#0000FF", mb: 1 }}
            >
              Estimated Time : {data.estimatedTime} hr
            </Typography>
          </Grid>
        </Grid>
        <Box sx={{ height: "50vh" }}>
          <Box
            sx={{
              height: "45vh",
              overflowX: "hidden",
              marginBottom: 2,
              ml: 2,
              mr: 2,
              border: "1px solid #d3d3d3",
              borderRadius: 1,
              "&::-webkit-scrollbar": {
                width: "3px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#ccc",
                borderRadius: "3px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: "#aaa",
              },
            }}
          >
            <DataGrid
              rows={rows}
              columns={columns}
              autoHeight
              hideFooter
              pageSize={10}
              pagination
              rowCount={rows.length}
            />
          </Box>
          <CustomPagination
            currentPage={currentPage}
            totalPage={Math.ceil(tableData.length / 10)}
            handlePageClick={handlePageChange}
            handleFirstPageClick={handleFirstPageClick}
            handleLastPageClick={handleLastPageClick}
            handleLeftClick={handleLeftClick}
            handleRightClick={handleRightClick}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default LiveCallsTableComponent;
