import React, { useState } from "react";
import {
  Card,
  CardContent,
  TextField,
  Autocomplete,
  Typography,
  Avatar,
  Box,
  Grid,
  FormControlLabel,
  Switch,
} from "@mui/material";
import wft from "../../../../assets/images/wft.png";
import { Email, Phone } from "@mui/icons-material";
import CallerDetails from "./CallerDetails";
import ClientAutocomplete from "app/components/clientAutocomplete/clientAutocomplete";
import ClientAutoCompleteComponent from "app/components/clientAutocomplete/clientAutocomplete";

const ClientDetailsComponent = ({
  clientDetails,
  options,
  handleClientChange,
  callerInfo,
  handleInputChange,
  handleSwitchChange,
  showCallerDetails,
  callerNumber,
}) => {
  const correctedImagePath = clientDetails?.clientImage?.replace(/\\/g, "/");

  return (
    <>
      <Card sx={{ borderRadius: "12px", height: "32vh" }}>
        <CardContent>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6">Client Details</Typography>
            <ClientAutoCompleteComponent
              handleClientChange={(client) =>
                handleClientChange(client, callerNumber)
              }
            />
          </Box>

          <Box sx={{ display: "flex", mt: 2, alignItems: "center" }}>
            <Avatar
              alt="User Name"
              sx={{ width: 100, height: 100 }}
              src={`https://kyc.naasasecurities.com.np/${correctedImagePath}`}
            />
            <Box
              display="flex"
              flexDirection="column"
              gap="1em"
              width="100%"
              sx={{ ml: "1em" }}
            >
              <Typography fontWeight="bold" fontSize="25px">
                {clientDetails?.clientName || "Unknown"} (
                {clientDetails?.clientId || "Unknown"})
              </Typography>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                gap="1px"
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  gap="10px"
                  color="#23239C"
                >
                  <Phone fontSize="25px" />
                  <Typography variant="span" fontSize="18px" color="#08244A">
                    {clientDetails?.clientPhone || "Not Found"}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  gap="1px"
                >
                  GrandFather's Name :
                  <Typography variant="span" fontSize="18px" fontWeight="bold">
                    {clientDetails?.clientGrandFatherName || "Not Found"}
                  </Typography>
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                gap="1px"
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  gap="10px"
                  color="#23239C"
                >
                  <Email fontSize="25px" />
                  <Typography variant="span" fontSize="18px" color="#08244A">
                    {clientDetails?.clientEmail || "Not Found"}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  gap="1px"
                >
                  Father's Name :
                  <Typography variant="span" fontSize="18px" fontWeight="bold">
                    {clientDetails?.clientFatherName || "Not Found"}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>

      {showCallerDetails && (
        <CallerDetails
          callerInfo={callerInfo}
          handleInputChange={handleInputChange}
          handleSwitchChange={handleSwitchChange}
        />
      )}
    </>
  );
};

export default ClientDetailsComponent;
